import api from '../api';
import {stringifyQueryParams} from '../utils';
import {
    CONFIG_ENDPOINT,
    JOBS_ENDPOINT,
    AUTH_ENDPOINT,
    TWO_STEP_ENDPOINT,
    COMPANIES_ENDPOINT,
    TERMS_AND_CONDITIONS_ENDPOINT,
    EDUCATION_AND_COMMUNICATION_ENDPOINT,
    ELIGIBILITY_ENDPOINT,
    EXPORT_ENDPOINT,
    GROUPS_ENDPOINT,
    INSURANCE_ENDPOINT,
    REPORTING_ENDPOINT,
    BROADCASTS_ENDPOINT,
    TIMELINE_ENDPOINT,
    PROFILE_ENDPOINT,
    USER_ENDPOINT,
    WALLETS_ENDPOINT,
    VERSION_HISTORY_ENDPOINT,
    TIMELINE_TEMPLATES_ENDPOINT,
    APP_CONFIGURATIONS_ENDPOINT,
    SFTP_ENDPOINT,
    RECOMMENDATIONS_MANAGER_ENDPOINT,
    IMPORTS_ENDPOINT,
    TPA_PARTNERS_ENDPOINT,
    PROFILE_EXTERNAL_ENDPOINT,
    AUTOIMPORTS_ENDPOINT,
    PROVIDER_BASKET_ENDPOINT
} from './constants';
import {HTTP_RESPONSE_TYPES, PLAN_SOURCE} from '../constants';

const apiServices = api => ({
    getFromThirdParty: async (url, params, axiosConfig) => await api.get(url, params, {...axiosConfig, isThirdParty: true}),

    setToThirdParty: async (url, params, axiosConfig) => await api.post(url, params, {...axiosConfig, isThirdParty: true}),

    getJobs: async ({type, status, ...context}) => {
        return await api.get(`${JOBS_ENDPOINT}`, null, {params: {type, status, ...context}, version: 2});
    },

    getJob: async jobId => await api.get(`${JOBS_ENDPOINT}/${jobId}`, null, {isLoader: false, isCancelable: true, version: 2}),

    setJob: async ({type, ...context}) => await api.post(JOBS_ENDPOINT, {type, ...context}, {version: 2}),

    getFeatureFlag: async ({featureFlag, ...context}) => await api.get(`${CONFIG_ENDPOINT}/feature_flags/${featureFlag}`, null, {params: {...context}}),

    login: async ({username, password}) => await api.post(`${AUTH_ENDPOINT}/access`, {username, password}, {noRefresh: true}),

    verifyCode: async ({twoStepToken: two_step_token, twoStepCode: two_step_code}) => await api.post(`${TWO_STEP_ENDPOINT}/verify`, {two_step_code, two_step_token}),

    resendCode: async ({twoStepToken: two_step_token, twoStepCodeChannel: two_step_code_channel}) => await api.post(`${TWO_STEP_ENDPOINT}/code`, {two_step_token, two_step_code_channel}),

    signOut: async ({accessToken, refreshToken}) => await Promise.all([
        api.delete(`${AUTH_ENDPOINT}/access`, null, {params: {token: accessToken}, noRefresh: true}),
        api.delete(`${AUTH_ENDPOINT}/refresh`, null, {params: {token: refreshToken}, noRefresh: true})
    ]),

    refreshToken: async ({token}) => await api.post(`${AUTH_ENDPOINT}/refresh`, {}, {params: {token}, noRefresh: true}),

    recoverPassword: async ({username}) => await api.post(`${PROFILE_ENDPOINT}/password/recover`, {username}),

    checkPasswordGrantToken: async ({userId: user_id, token}) => await api.post(`${PROFILE_ENDPOINT}/password/check_grant_token`, {user_id, token}),

    setPassword: async ({userId: user_id, token, password}) => await api.post(`${PROFILE_ENDPOINT}/password/set`, {user_id, token, password}),

    getAuthInfo: async ({companyAlias}) => await api.get(`${GROUPS_ENDPOINT}/${companyAlias}/security_config`, null, {params: null}),

    getUserAuthData: async () => await api.get(`${GROUPS_ENDPOINT}/my/security_config`, null, {params: null}),

    updateAuthInfo: async ({companyAlias, authInfo}) => await api.put(`${GROUPS_ENDPOINT}/${companyAlias}/security_config`, authInfo),

    getProfileInfo: async ({companyAlias: company_alias} = {}) => await api.get(`${PROFILE_ENDPOINT}/me`, null, {params: {company_alias}}),

    setUserOnboardedData: async ({onboardedType: onboarded_type}) => await api.post(`${PROFILE_ENDPOINT}/me/onboarded`, {onboarded_type}),

    setTermsAndConditionsSign: async ({companyAlias: company_alias, isSigned: is_signed}) => await api.post(`${TERMS_AND_CONDITIONS_ENDPOINT}/`, {company_alias, is_signed}),

    requestVersionHistoryList: async ({versionHistoryType: object_type, versionHistoryId: object_id}) => await api.get(`${VERSION_HISTORY_ENDPOINT}`, null, {params: {object_id, object_type}}),

    requestVersionHistoryDetails: async versionId => await api.get(`${VERSION_HISTORY_ENDPOINT}/${versionId}`, null, {params: null}),

    setBroadcasterOnboarded: async () => await api.patch(`${PROFILE_ENDPOINT}/me`, {is_broadcaster_onboarded: true}),

    getCompanyByAlias: async ({companyAlias}) => await api.get(`${COMPANIES_ENDPOINT}/by_alias/${companyAlias}`, null),

    getActiveCompany: async () => await api.get(`${COMPANIES_ENDPOINT}/active_company`, null),

    getCompanies: async ({query, limit = 20, offset = 0, isInactiveIncluded = false, excludeTpaClients = false, isLoader = false, isCancelable = true}) => {
        return await api.get(COMPANIES_ENDPOINT, null, {params: {query, limit, offset, include_inactive: isInactiveIncluded, exclude_tpa_clients: excludeTpaClients}, isLoader, isCancelable});
    },

    getGroups: async ({query, offset = 0, limit = 20, excludeTpaClients = true}) => {
        return await api.get(`${IMPORTS_ENDPOINT}/groups`, null, {params: {offset, limit, query, exclude_tpa_clients: excludeTpaClients}});
    },

    updatePendingGroup: async ({companyAlias, ...groupInfo}) => {
        return await api.patch(`${IMPORTS_ENDPOINT}/groups/${companyAlias}/metadata`, groupInfo);
    },

    getGroupsByTPAPartner: async ({partnerId, offset, limit = 20, alias, aliasList, excludeRenewals = true}) => {
        return await api.get(`${IMPORTS_ENDPOINT}/tpa/${partnerId}/groups`, null, {params: {offset, limit, alias, alias_list: aliasList, arrayFormat: 'none', exclude_renewals: excludeRenewals}});
    },

    getTPAPartners: async ({query, offset, limit = 20}) => {
        return await api.get(TPA_PARTNERS_ENDPOINT, null, {params: {offset, limit, query}});
    },

    getTPAPartner: async ({id}) => await api.get(`${TPA_PARTNERS_ENDPOINT}/${id}`),

    setActiveCompany: async ({companyId: company_id}) => await api.post(`${PROFILE_ENDPOINT}/last_company`, {company_id}),

    getManagersList: async ({type}) => await api.get(`${USER_ENDPOINT}/`, {type}),

    validateUser: async ({query}) => await api.get(`${USER_ENDPOINT}/validate`, null, {params: query, isLoader: false}),

    generateTimeline: async ({timelineInfo}) => {
        return await api.post(`${TIMELINE_ENDPOINT}/`, timelineInfo);
    },

    getTimeline: async ({timelineId}) => await api.get(`${TIMELINE_ENDPOINT}/${timelineId}`),

    updateTimeline: async ({timelineId, timelineInfo}) => {
        return await api.patch(`${TIMELINE_ENDPOINT}/${timelineId}`, timelineInfo);
    },

    updateTimelineStep: async ({stepId, updatedFields}) => {
        return await api.patch(`${TIMELINE_ENDPOINT}/step/${stepId}`, updatedFields);
    },

    getContactList: async ({companyId, role, active}) => await api.get(`${COMPANIES_ENDPOINT}/${companyId}/contacts`, {role, active}),

    getContact: async ({companyId, user_id}) => await api.get(`${COMPANIES_ENDPOINT}/${companyId}/contact`, {user_id}),

    addContact: async ({companyId, contact}) => await api.post(`${COMPANIES_ENDPOINT}/${companyId}/contacts`, contact),

    updateContact: async ({companyId, contact, reinvite}) => {
        return await api.put(`${COMPANIES_ENDPOINT}/${companyId}/contact`, {...contact, reinvite});
    },

    deleteContact: async ({companyId, user_id}) => {
        return await api.delete(`${COMPANIES_ENDPOINT}/${companyId}/contact`, null, {params: {user_id}});
    },

    inviteContact: async ({companyId, user_id}) => {
        return await api.post(`${COMPANIES_ENDPOINT}/${companyId}/reinvite_contact/${user_id}`, null);
    },

    getCompanyDetails: async ({companyId}) => await api.get(`${COMPANIES_ENDPOINT}/${companyId}`),

    editCompanyDetails: async ({companyId, companyInfo}) => await api.patch(`${COMPANIES_ENDPOINT}/${companyId}`, companyInfo),

    getCompanyEmployees: async ({companyId, query, limit = 50}) => {
        return await api.get(`${COMPANIES_ENDPOINT}/${companyId}/employees`, null, {params: {query, limit}, isLoader: false, isCancelable: true});
    },

    getSearchedCompanyEmployees: async ({companyId, query, sortBy, orderBy, limit = 50, offset = 0}) => {
        const config = {params: {query, limit, offset, sort_by: sortBy, order_by: orderBy}, isCancelable: true};

        return await api.get(`${COMPANIES_ENDPOINT}/${companyId}/employees/search`, null, config);
    },

    getCompanyEmployeesReport: async ({companyId}) => await api.get(`${COMPANIES_ENDPOINT}/${companyId}/employees/download`, null, {responseType: HTTP_RESPONSE_TYPES.blob}),

    getCompanyEmployeeCount: async ({companyId}) => await api.get(`${COMPANIES_ENDPOINT}/employee_count/${companyId}`),

    getIndustryList: async () => await api.get(`${COMPANIES_ENDPOINT}/industries`),

    getReasonList: async () => await api.get(`${COMPANIES_ENDPOINT}/use_reasons`),

    getExportedEligibilityFiles: async ({companyAlias, startDate, endDate, limit = 100, offset = 0}) => {
        const params = {company_alias: companyAlias, from_date: startDate, to_date: endDate, limit, offset};

        return await api.get(`${EXPORT_ENDPOINT}/enrollments`, null, {params, version: 2});
    },

    getBenefitsClasses: async ({companyId, planPeriodId: plan_period_id}) => await api.get(`${ELIGIBILITY_ENDPOINT}/${companyId}/member_classes`, null, {params: {plan_period_id}}),

    setBenefitsClasses: async ({companyId, classesInfo}) => await api.post(`${ELIGIBILITY_ENDPOINT}/${companyId}/member_classes`, classesInfo),

    getCorePlansByType: async ({companyAlias, planPeriodId: plan_period_id, planType}) => {
        return await api.get(`${INSURANCE_ENDPOINT}/${companyAlias}/core/${planType}`, null, {params: {plan_period_id}});
    },

    setCorePlansByType: async ({companyAlias, planPeriodId: plan_period_id, planType, plans}) => {
        return await api.post(`${INSURANCE_ENDPOINT}/${companyAlias}/core/${planType}`, {plan_period_id, plans});
    },

    getMedicalTraits: async ({companyAlias, planPeriodId: plan_period_id}) => await api.get(`${INSURANCE_ENDPOINT}/${companyAlias}/medical_traits`, null, {params: {plan_period_id}}),

    setMedicalTraits: async ({companyAlias, planPeriodId: plan_period_id, medicalTraits: medical_traits}) => {
        return await api.post(`${INSURANCE_ENDPOINT}/${companyAlias}/medical_traits`, {plan_period_id, medical_traits});
    },

    getCorePlansInfo: async ({companyAlias, planPeriodId: plan_period_id}) => await api.get(`${INSURANCE_ENDPOINT}/${companyAlias}/plans_info`, null, {params: {plan_period_id}}),

    getPlanDetails: async ({companyAlias, planType, planId}) => {
        return await api.get(`${INSURANCE_ENDPOINT}/${companyAlias}/plan_details/${planType}/${planId}`);
    },

    setPlanDetails: async ({companyAlias, planType, planId, planDetails}) => {
        return await api.post(`${INSURANCE_ENDPOINT}/${companyAlias}/plan_details/${planType}/${planId}`, planDetails);
    },

    updatePlanDetails: async ({companyAlias, planType, planId, updatedFields}) => {
        return await api.patch(`${INSURANCE_ENDPOINT}/${companyAlias}/plan_details/${planType}/${planId}`, updatedFields);
    },

    getAdditionalInfo: async ({companyAlias, planId}) => await api.get(`${INSURANCE_ENDPOINT}/${companyAlias}/additional_plan_info/${planId}`),

    setAdditionalInfo: async ({companyAlias, planId, planInfo}) => await api.post(`${INSURANCE_ENDPOINT}/${companyAlias}/additional_plan_info/${planId}`, planInfo),

    getAncillaryPlansOverview: async ({companyAlias, planPeriodId: plan_period_id}) => await api.get(`${INSURANCE_ENDPOINT}/${companyAlias}/ancillary/general`, null, {params: {plan_period_id}}),

    setAncillaryPlansOverview: async ({companyAlias, planPeriodId: plan_period_id, plans, templates}) => {
        return await api.post(`${INSURANCE_ENDPOINT}/${companyAlias}/ancillary/general`, {plan_period_id, plans, templates});
    },

    getAncillaryTemplates: async () => await api.get(`${INSURANCE_ENDPOINT}/ancillary/templates`),

    getAncillaryPlansInfo: async ({companyAlias, planPeriodId: plan_period_id}) => await api.get(`${INSURANCE_ENDPOINT}/${companyAlias}/ancillary_plans`, null, {params: {plan_period_id}}),

    getAncillaryPlanDetails: async ({companyAlias, planId}) => {
        return await api.get(`${INSURANCE_ENDPOINT}/${companyAlias}/ancillary_plan_details/full/${planId}`);
    },

    setAncillaryPlanDetails: async ({companyAlias, planId, ancillaryPlanDetails}) => {
        return await api.post(`${INSURANCE_ENDPOINT}/${companyAlias}/ancillary_plan_details/full/${planId}`, ancillaryPlanDetails);
    },

    deleteAncillaryPlanDetails: async ({companyAlias, planId}) => {
        return await api.delete(`${INSURANCE_ENDPOINT}/${companyAlias}/ancillary_plan_details/full/${planId}`);
    },

    getCustomizedAncillaryPlanDetails: async ({companyAlias, planId}) => {
        return await api.get(`${INSURANCE_ENDPOINT}/${companyAlias}/ancillary_plan_details/customized/${planId}`);
    },

    setCustomizedAncillaryPlanDetails: async ({companyAlias, planId, ancillaryPlanDetails}) => {
        return await api.post(`${INSURANCE_ENDPOINT}/${companyAlias}/ancillary_plan_details/customized/${planId}`, ancillaryPlanDetails);
    },

    deleteCustomizedAncillaryPlanDetails: async ({companyAlias, planId}) => {
        return await api.delete(`${INSURANCE_ENDPOINT}/${companyAlias}/ancillary_plan_details/customized/${planId}`);
    },

    getBenefitGuideDetails: async ({companyAlias, planPeriodId: plan_period_id}) => await api.get(`${INSURANCE_ENDPOINT}/${companyAlias}/benefit_guide`, null, {params: {plan_period_id}}),

    setBenefitGuideDetails: async ({companyAlias, planPeriodId: plan_period_id, benefitGuideDetails}) => {
        return await api.post(`${INSURANCE_ENDPOINT}/${companyAlias}/benefit_guide`, {plan_period_id, ...benefitGuideDetails});
    },

    getAncillaryCarrierList: async ({query, limit = 50}) => {
        return await api.get(`${INSURANCE_ENDPOINT}/ancillary_carrier`, null, {params: {query, limit}, isLoader: false, isCancelable: true});
    },

    getPharmacyBenefitManagerList: async ({query, limit = 50}) => {
        return await api.get(`${INSURANCE_ENDPOINT}/pbm`, null, {params: {query, limit}, isLoader: false, isCancelable: true});
    },

    getConsultFees: async ({companyAlias, planPeriodId}) => {
        return await api.get(`${INSURANCE_ENDPOINT}/consult_fees/`, null, {params: {plan_period_id: planPeriodId, company_alias: companyAlias}});
    },

    getCategoryAndTrackingInfoDetailsConfig: async () => await api.get(`${WALLETS_ENDPOINT}/category_and_tracking_info/config`),

    getCategoryAndTrackingInfoDataById: async ({id}) => await api.get(`${WALLETS_ENDPOINT}/category_and_tracking_info/categories/${id}`),

    setCategoryAndTrackingInfo: async ({companyAlias, planId, cardView}) => {
        return await api.post(`${WALLETS_ENDPOINT}/category_and_tracking_info/`, cardView, {params: {company_alias: companyAlias, plan_source: PLAN_SOURCE.ancillary, plan_id: planId}});
    },

    getWalletLinks: async ({companyAlias}) => await api.get(`${WALLETS_ENDPOINT}/${companyAlias}/wallet_links`),

    getAllWalletCardsPreviewParams: async ({companyId, planPeriodId: plan_period_id}) => await api.get(`${COMPANIES_ENDPOINT}/${companyId}/all_wallet_cards_preview_params`, null, {params: {plan_period_id}}),

    getPlanPreviewParams: async ({companyId, planId, planPeriodId: plan_period_id}) => await api.get(`${COMPANIES_ENDPOINT}/${companyId}/plan_preview_params/${planId}`, null, {params: {plan_period_id}}),

    getEmployeePreviewParams: async ({planPeriodId: plan_period_id, userId, date}) => {
        return await api.get(`${COMPANIES_ENDPOINT}/employee_preview_params/${userId}`, null, {params: {dt: date, plan_period_id}});
    },

    getWalletCards: async ({companyId, planPeriodId: plan_period_id}) => await api.get(`${WALLETS_ENDPOINT}/${companyId}/wallet`, null, {params: {plan_period_id}}),

    setWalletCards: async ({companyId, walletCards: cards}) => await api.patch(`${WALLETS_ENDPOINT}/${companyId}/wallet`, {cards}),

    getEligibilityInfo: async ({companyId, planPeriodId: plan_period_id}) => await api.get(`${ELIGIBILITY_ENDPOINT}/${companyId}/info`, null, {params: {plan_period_id}}),

    setEligibilityInfo: async ({companyId, info}) => await api.post(`${ELIGIBILITY_ENDPOINT}/${companyId}/info`, info),

    getCorePlansEligibilityInfo: async ({companyId, planPeriodId: plan_period_id}) => await api.get(`${ELIGIBILITY_ENDPOINT}/${companyId}/sources`, null, {params: {plan_period_id}}),

    setCorePlansEligibilityInfo: async ({companyId, corePlansInfo}) => await api.post(`${ELIGIBILITY_ENDPOINT}/${companyId}/sources`, corePlansInfo),

    getAncillaryPlansEligibilityInfo: async ({companyId, planPeriodId: plan_period_id}) => await api.get(`${ELIGIBILITY_ENDPOINT}/${companyId}/ancillary_eligibility`, null, {params: {plan_period_id}}),

    setAncillaryPlansEligibilityInfo: async ({companyId, ancillaryPlansInfo}) => await api.post(`${ELIGIBILITY_ENDPOINT}/${companyId}/ancillary_eligibility`, ancillaryPlansInfo),

    getBenAdminList: async ({query, limit = 20}) => {
        return await api.get(`${ELIGIBILITY_ENDPOINT}/benadmin-systems/suggestions`, null, {params: {name_query: query, limit}, isLoader: false, isCancelable: true, version: 2});
    },

    setBenAdmin: async ({name}) => await api.post(`${ELIGIBILITY_ENDPOINT}/benadmin-systems`, {name}, {version: 2}),

    getEligibilityFilesInfoList: async ({companyId, planPeriodId: plan_period_id}) => await api.get(`${ELIGIBILITY_ENDPOINT}/${companyId}/files_info`, null, {params: {plan_period_id}}),

    getEligibilityFilesInfoDetails: async ({companyId, planPeriodId: plan_period_id, category}) => await api.get(`${ELIGIBILITY_ENDPOINT}/${companyId}/files_info_details/${category}`, null, {params: {plan_period_id}}),

    setEligibilityFilesInfoDetails: async ({companyId, category, details}) => {
        return await api.post(`${ELIGIBILITY_ENDPOINT}/${companyId}/files_info_details/${category}`, details);
    },

    getBenefitsOverview: async ({companyId}) => await api.get(`${ELIGIBILITY_ENDPOINT}/${companyId}/benefits_overview`),

    getOpenEnrollment: async ({companyId}) => await api.get(`${ELIGIBILITY_ENDPOINT}/${companyId}/renewal`),

    setOpenEnrollment: async ({companyId, overview}) => await api.patch(`${ELIGIBILITY_ENDPOINT}/${companyId}/renewal`, overview),

    getReportingInfo: async ({companyAlias: company_alias, reportingSection: section, reportingPlanPeriod: period}) => {
        return await api.get(`${REPORTING_ENDPOINT}/`, null, {params: {company_alias, section, period}});
    },

    getDownloadReports: async ({companyAlias: company_alias, reportingPlanPeriod: period}) => {
        return await api.get(`${REPORTING_ENDPOINT}/${company_alias}/download_reports`, null, {params: {period}});
    },

    getReportingDashboardHiddenTiles: async ({dashboardId: dashboard_id}) => {
        return await api.get(`${REPORTING_ENDPOINT}/looker_dashboard_tiles/`, null, {params: {dashboard_id, hidden: 1}});
    },

    setReportDownloading: async ({dashboardId: dashboard_id, filters}) => {
        return await api.post(`${REPORTING_ENDPOINT}/looker_download_report`, {filters}, {params: {dashboard_id}, responseType: HTTP_RESPONSE_TYPES.blob});
    },

    getMarketingPages: async () => await api.get(`${EDUCATION_AND_COMMUNICATION_ENDPOINT}/pages`),

    getMarketingPageInfo: async ({pageId}) => await api.get(`${EDUCATION_AND_COMMUNICATION_ENDPOINT}/pages/${pageId}`),

    getBroadcasts: async ({companyAlias, query, sortBy, orderBy, scheduledFor, status, limit = 50, offset = 0, version = 2}) => {
        const config = {params: {company_alias: companyAlias, query, limit, offset, status, sort_by: sortBy, order_by: orderBy, scheduled_for: scheduledFor}, isCancelable: true, version};

        return await api.get(`${BROADCASTS_ENDPOINT}/`, null, config);
    },

    createBroadcast: async ({companyAlias, broadcast, version = 2}) => await api.post(`${BROADCASTS_ENDPOINT}/`, broadcast, {params: {company_alias: companyAlias}, version}),

    updateBroadcast: async ({companyAlias, id, broadcast, version = 2}) => await api.patch(`${BROADCASTS_ENDPOINT}/${id}`, broadcast, {params: {company_alias: companyAlias}, version}),
    deleteBroadcast: async ({companyAlias, id, broadcast, version = 2}) => await api.delete(`${BROADCASTS_ENDPOINT}/${id}`, broadcast, {params: {company_alias: companyAlias}, version}),

    getBroadcast: async ({companyAlias, id, version = 2}) => await api.get(`${BROADCASTS_ENDPOINT}/${id}`, null, {params: {company_alias: companyAlias}, version}),

    getBroadcastTemplates: async ({companyAlias, version = 2}) => await api.get(`${BROADCASTS_ENDPOINT}/templates/`, null, {params: {company_alias: companyAlias}, version}),

    createCommunicationByEmail: async ({companyAlias, broadcast}) => {
        const {email, content} = broadcast;

        return await api.post(`${BROADCASTS_ENDPOINT}/communication-records/`, {email, content}, {params: {company_alias: companyAlias}});
    },

    // TODO: Move adding appId && appSecret logic to api.js after adding 2 factor authentication.
    getPdfReportInfo: async ({reportType, companyAlias, dataPath, appId, appSecret}) => {
        return await api.get(`${REPORTING_ENDPOINT}/pdf/${dataPath}`, null, {params: {app_id: appId, app_secret: appSecret, company_alias: companyAlias, report_type: reportType}});
    },

    setAncillaryConditionalVariables: async ({companyAlias, planId, conditions}) => {
        return await api.post(`${WALLETS_ENDPOINT}/availability_conditions/`, conditions, {params: {company_alias: companyAlias, plan_source: PLAN_SOURCE.ancillary, plan_id: planId}});
    },

    setCoreConditionalVariables: async ({companyAlias, planId, conditions}) => {
        return await api.post(`${WALLETS_ENDPOINT}/availability_conditions/`, conditions, {params: {company_alias: companyAlias, plan_source: PLAN_SOURCE.insurance, plan_id: planId}});
    },

    getDefaultConditionalVariables: async ({category}) => {
        return await api.get(`${WALLETS_ENDPOINT}/availability_conditions/`, null, {params: {category}});
    },

    getCorePlans: async ({query, isLoader = true, isCancelable = false, limit = 50}) => {
        return await api.get(`${INSURANCE_ENDPOINT}/core_plans/`, null, {params: {...query, limit}, isLoader, isCancelable, version: 2});
    },

    getCorePlan: async ({companyAlias: company_alias, planId}) => {
        return await api.get(`${INSURANCE_ENDPOINT}/core_plans/${planId}`, null, {params: {company_alias}, version: 2});
    },

    updateCorePlan: async ({planId, versionId, planDetails}) => {
        return await api.patch(`${INSURANCE_ENDPOINT}/core_plans/${planId}`, planDetails, {params: {from_revision: versionId}, version: 2});
    },

    deleteCorePlan: async ({companyAlias: company_alias, planId}) => {
        return await api.delete(`${INSURANCE_ENDPOINT}/core_plans/${planId}`, null, {params: {company_alias}, version: 2});
    },

    getAncillaryPlans: async ({query, isLoader = true, isCancelable = false, limit = 50}) => {
        return await api.get(`${INSURANCE_ENDPOINT}/ancillary_plans/`, null, {params: {...query, limit}, isLoader, isCancelable, version: 2});
    },

    getMedtoolPlanRevisions: async ({planId: core_plan_id}) => {
        return await api.get(`${INSURANCE_ENDPOINT}/medtool_plan_revisions/`, null, {params: {core_plan_id}, version: 2});
    },

    getMedtoolPlanRevision: async ({planId: core_plan_id, revisionId, comparisonRevisionsIds}) => {
        return await api.get(`${INSURANCE_ENDPOINT}/medtool_plan_revisions/${revisionId}`, null, {params: {core_plan_id, compare_to: comparisonRevisionsIds}, version: 2});
    },

    getMedtoolIssuers: async ({query, limit = 50, isLoader = false, isCancelable = true}) => {
        return await api.get(`${INSURANCE_ENDPOINT}/medtool_issuers/`, null, {params: {...query, limit}, isLoader, isCancelable, version: 2});
    },

    getBenefitsCategories: async () => {
        return await api.get(`${INSURANCE_ENDPOINT}/benefits_categories`, null, {version: 2});
    },

    getZendeskChat: async () => {
        return await api.get(`${AUTH_ENDPOINT}/external/zendesk_chat`, null, {isLoader: false});
    },

    getZendeskHelpCenter: async () => {
        return await api.get(`${AUTH_ENDPOINT}/external/zendesk_help_center`, null);
    },

    getZendeskSSO: async () => {
        return await api.get(`${AUTH_ENDPOINT}/external/zendesk_sso`, null);
    },

    getZendeskProfileSSO: async () => {
        return await api.get(`${PROFILE_EXTERNAL_ENDPOINT}/zendesk_sso_token`, null, {isLoader: false});
    },

    getMedtoolIssuer: async ({carrierId}) => {
        return await api.get(`${INSURANCE_ENDPOINT}/medtool_issuers/${carrierId}`, null, {version: 2});
    },

    createMedtoolIssuer: async ({carrierDetails}) => {
        return await api.post(`${INSURANCE_ENDPOINT}/medtool_issuers/`, carrierDetails, {version: 2});
    },

    updateMedtoolIssuer: async ({carrierId, carrierDetails}) => {
        return await api.patch(`${INSURANCE_ENDPOINT}/medtool_issuers/${carrierId}`, carrierDetails, {version: 2});
    },

    deleteMedtoolIssuer: async ({carrierId}) => {
        return await api.delete(`${INSURANCE_ENDPOINT}/medtool_issuers/${carrierId}`, null, {version: 2});
    },

    getInsuranceNetworks: async ({limit = 20, ...query}) => await api.get(`${INSURANCE_ENDPOINT}/networks`, null, {version: 2, isLoader: false, isCancelable: true, params: {limit, ...query}}),

    getTimelineTemplates: async ({query, isLoader = true, isCancelable = false}) => {
        return await api.get(`${TIMELINE_TEMPLATES_ENDPOINT}/`, null, {params: {...query}, isLoader, isCancelable, version: 2});
    },

    getTimelineTemplate: async ({templateId}) => {
        return await api.get(`${TIMELINE_TEMPLATES_ENDPOINT}/${templateId}`, null, {version: 2});
    },

    deleteTimelineTemplate: async ({templateId}) => {
        return await api.delete(`${TIMELINE_TEMPLATES_ENDPOINT}/${templateId}`, null, {version: 2});
    },

    createTimelineTemplate: async ({timelineTemplate}) => {
        return await api.post(`${TIMELINE_TEMPLATES_ENDPOINT}/`, timelineTemplate, {version: 2});
    },

    updateTimelineTemplate: async ({templateId, timelineTemplate}) => {
        return await api.patch(`${TIMELINE_TEMPLATES_ENDPOINT}/${templateId}`, timelineTemplate, {version: 2});
    },

    getAppConfigurationConsultFees: async ({companyAlias}) => {
        return await api.get(`${APP_CONFIGURATIONS_ENDPOINT}/${companyAlias}/consult-fees/`);
    },

    updateAppConfigurationConsultFees: async ({companyAlias, config}) => {
        return await api.put(`${APP_CONFIGURATIONS_ENDPOINT}/${companyAlias}/consult-fees/`, config);
    },

    getAppConfigurations: async ({companyAlias}) => {
        return await api.get(`${APP_CONFIGURATIONS_ENDPOINT}/${companyAlias}`);
    },

    getAppConfigurationByType: async ({companyAlias, type}) => {
        return await api.get(`${APP_CONFIGURATIONS_ENDPOINT}/${companyAlias}/${type}/`);
    },

    updateAppConfigurationByType: async ({companyAlias, type, config}) => {
        return await api.put(`${APP_CONFIGURATIONS_ENDPOINT}/${companyAlias}/${type}/`, config);
    },

    getMailOrderPrograms: async () => {
        return await api.get(`${APP_CONFIGURATIONS_ENDPOINT}/mail_order_programs`);
    },

    generateGroupId: async () => {
        return await api.get(`${APP_CONFIGURATIONS_ENDPOINT}/eligibility_groups/generate_id`);
    },

    createSFTPPasswordLink: async ({credentialsId}) => {
        return await api.post(`${SFTP_ENDPOINT}/passgen/link`, {creds_id: credentialsId});
    },

    createSFTPTemporaryPassword: async ({credentialsId}) => {
        return await api.post(`${SFTP_ENDPOINT}/passgen/temporary_password`, {creds_id: credentialsId});
    },

    getSFTPCredentials: async ({id}) => {
        return await api.get(`${SFTP_ENDPOINT}/creds/${id}`);
    },

    getSFTPCredentialsList: async ({hostType, isMultiClient, multiClientFeedNameQuery, usernameQuery, limit = 50, isLoader = false, isCancelable = false}) => {
        const params = {host_type: hostType, is_multi_client_feed: isMultiClient, multi_client_feed_name: multiClientFeedNameQuery, username: usernameQuery, limit};

        return await api.get(`${SFTP_ENDPOINT}/creds/`, null, {isLoader, isCancelable, params});
    },

    createSFTPCredentials: async ({credentialsInfo}) => {
        return await api.post(`${SFTP_ENDPOINT}/creds/`, credentialsInfo);
    },

    getRecommendationsManagerDetailsConfig: async () => {
        return await api.get(`${RECOMMENDATIONS_MANAGER_ENDPOINT}/config/card_details`);
    },

    getRecommendationsManagerListConfig: async () => {
        return await api.get(`${RECOMMENDATIONS_MANAGER_ENDPOINT}/config/list`);
    },

    createRecommendationsCardDraft: async ({cardDetails}) => {
        return await api.post(`${RECOMMENDATIONS_MANAGER_ENDPOINT}/card/draft`, cardDetails);
    },

    updateRecommendationsCardDraft: async ({id, cardDetails}) => {
        return await api.put(`${RECOMMENDATIONS_MANAGER_ENDPOINT}/card/${id}/draft`, cardDetails);
    },

    getRecommendationsCard: async ({cardId}) => {
        return await api.get(`${RECOMMENDATIONS_MANAGER_ENDPOINT}/card/${cardId}`);
    },

    getRecommendationsCards: async ({query}) => {
        return await api.get(`${RECOMMENDATIONS_MANAGER_ENDPOINT}/cards`, null, {params: query});
    },

    publishCard: async ({cardDetails}) => {
        return api.post(`${RECOMMENDATIONS_MANAGER_ENDPOINT}/card/publish`, cardDetails);
    },

    updatePublishCard: async ({id, cardDetails}) => {
        return api.put(`${RECOMMENDATIONS_MANAGER_ENDPOINT}/card/${id}/publish`, cardDetails);
    },

    unPublishCard: async ({id}) => {
        return api.post(`${RECOMMENDATIONS_MANAGER_ENDPOINT}/card/${id}/unpublish`);
    },

    uploadPublicFile: async formData => {
        return api.post('/files/upload/public', formData);
    },

    getEligibilityImportSessions: async ({limit = 20, offset = 0, isLoader = false, isCancelable = true, ...query}) => {
        return await api.get(`${IMPORTS_ENDPOINT}/sessions`, null, {isLoader, isCancelable, params: {limit, offset, ...query}});
    },

    createEligibilityImportSession: async ({sessionDetails}) => {
        return await api.post(`${IMPORTS_ENDPOINT}/sessions`, sessionDetails);
    },

    updateEligibilityImportSession: async ({id, sessionDetails}) => await api.patch(`${IMPORTS_ENDPOINT}/sessions/${id}`, sessionDetails),

    getEligibilityImportSession: async ({id}) => await api.get(`${IMPORTS_ENDPOINT}/sessions/${id}`),

    getEligibilityImportSessionStats: async ({id}) => await api.get(`${IMPORTS_ENDPOINT}/sessions/${id}/stats`),

    getEligibilityImportSessionErrors: async ({id}) => await api.get(`${IMPORTS_ENDPOINT}/sessions/${id}/errors`),

    getEligibilityImportSessionErrorsDetails: async ({id, columnName, limit = 20, offset = 0}) => await api.get(`${IMPORTS_ENDPOINT}/sessions/${id}/errors/members`, null, {params: {limit, offset, column_name: columnName}}),

    getMassMembersChanges: async ({id}) => await api.get(`${IMPORTS_ENDPOINT}/sessions/${id}/members/mass_changes`),

    getEligibilityImportSessionMigratedMembers: async ({id, limit = 20, offset = 0, ...query}) => await api.get(`${IMPORTS_ENDPOINT}/sessions/${id}/members/pending_migration`, null, {params: {limit, offset, ...query}}),

    getEligibilityImportSessionTerminatedMembers: async ({id, limit = 20, offset = 0, ...query}) => await api.get(`${IMPORTS_ENDPOINT}/sessions/${id}/members/pending_termination`, null, {params: {limit, offset, ...query}}),

    getEligibilityImportUsers: async ({limit = 20, ...query}) => await api.get(`${IMPORTS_ENDPOINT}/sessions/users`, null, {isLoader: false, isCancelable: true, params: {limit, ...query}}),

    getImportConfigUsers: async query => await api.get(`${IMPORTS_ENDPOINT}/import-config/users`, null, {params: query}),

    getTPAPartnerConfig: async ({tpaPartnerId}) => await api.get(`${IMPORTS_ENDPOINT}/tpa-partner-configs`, null, {params: {tpa_partner_id: tpaPartnerId}}),

    getTPAPartnerConfigVersions: async ({tpaPartnerId, query}) => await api.get(`${IMPORTS_ENDPOINT}/import-config/${tpaPartnerId}/tpa-history`, null, {params: query}),

    getTPAPartnerConfigVersion: async ({sessionId}) => await api.get(`${IMPORTS_ENDPOINT}/import-config/tpa-versions/${sessionId}`),

    getIndividualImportConfig: async ({companyAlias}) => await api.get(`${ELIGIBILITY_ENDPOINT}/import-configs/company/${companyAlias}`),

    updateIndividualImportConfig: async configDetails => await api.patch(`${ELIGIBILITY_ENDPOINT}/import-configs/`, configDetails),

    getIndividualConfigAncillaryInfo: async ({configId, query}) => await api.get(`${ELIGIBILITY_ENDPOINT}/import-configs/${configId}/ancillary`, null, {params: query}),

    getIndividualConfigAncillaryCategories: async ({configId, query}) => await api.get(`${ELIGIBILITY_ENDPOINT}/import-configs/${configId}/ancillary-categories`, null, {params: query}),

    createIndividualConfigAncillaryCategory: async ({configId, categoryDetails}) => await api.post(`${ELIGIBILITY_ENDPOINT}/import-configs/${configId}/ancillary`, categoryDetails),

    updateIndividualConfigAncillaryCategory: async ({configId, categoryDetails}) => await api.patch(`${ELIGIBILITY_ENDPOINT}/import-configs/${configId}/ancillary`, categoryDetails),

    deleteIndividualConfigAncillaryCategory: async ({configId, category}) => await api.delete(`${ELIGIBILITY_ENDPOINT}/import-configs/${configId}/ancillary`, null, {params: {category}}),

    getIndividualImportConfigVersions: async ({companyAlias, query}) => await api.get(`${IMPORTS_ENDPOINT}/import-config/${companyAlias}/history`, null, {params: query}),

    getIndividualImportConfigVersion: async ({sessionId}) => await api.get(`${IMPORTS_ENDPOINT}/import-config/versions/${sessionId}`),

    getGroupAutomationStatus: async ({companyAlias}) => await api.get(`${AUTOIMPORTS_ENDPOINT}/automation-status`, null, {params: {company_alias: companyAlias}}),

    getAutomationHealthReport: async () => await api.get(`${AUTOIMPORTS_ENDPOINT}/health_report`),

    createTPAPartnerConfig: async configDetails => await api.post(`${IMPORTS_ENDPOINT}/tpa-partner-configs`, configDetails),

    updateTPAPartnerConfig: async ({tpaPartnerConfigId, ...configDetails}) => await api.patch(`${IMPORTS_ENDPOINT}/tpa-partner-configs/${tpaPartnerConfigId}`, configDetails),

    getTPAPartnerImportSessions: async ({limit = 20, isLoader = false, isCancelable = true, ...query}) => await api.get(`${IMPORTS_ENDPOINT}/tpa-partner-sessions`, null, {isLoader, isCancelable, params: {limit, ...query}}),

    createTPAPartnerImportSession: async ({session}) => await api.post(`${IMPORTS_ENDPOINT}/tpa-partner-sessions`, session),

    updateTPAPartnerImportSession: async ({session, sessionId}) => await api.patch(`${IMPORTS_ENDPOINT}/tpa-partner-sessions/${sessionId}`, session),

    getTPAPartnerImportSession: async ({sessionId}) => await api.get(`${IMPORTS_ENDPOINT}/tpa-partner-sessions/${sessionId}`),

    getTPAGroupTweak: async ({alias}) => await api.get(`${IMPORTS_ENDPOINT}/import-config-tweaks/${alias}`),

    updateTPAGroupTweak: async ({alias, tweak}) => await api.put(`${IMPORTS_ENDPOINT}/import-config-tweaks/${alias}`, tweak),

    updateTpaConfigTweaks: async query => await api.post(`${IMPORTS_ENDPOINT}/import-config/apply-tpa-config-tweaks`, query),

    createImportsJsonrpc: async ({body}) => await api.post(`${IMPORTS_ENDPOINT}/jsonrpc`, body),

    getPreprocessingReport: async ({sessionId}) => await api.get(`${IMPORTS_ENDPOINT}/tpa-partner-sessions/${sessionId}/preprocessing-report`),

    getImportSessionStatuses: async ({alias, status}) => await api.get(`${IMPORTS_ENDPOINT}/sessions/statuses`, null, {params: {arrayFormat: null, alias, status}}),

    getImportsCompanyConfig: async ({companyAlias}) => await api.get(`${IMPORTS_ENDPOINT}/company-config/${companyAlias}`),

    createImportsCompanyConfig: async query => await api.post(`${IMPORTS_ENDPOINT}/company-config`, query),

    updateImportsCompanyConfig: async query => await api.patch(`${IMPORTS_ENDPOINT}/company-config/${query.company_alias}`, query),

    getPendingGroupsInfo: async () => await api.get(`${IMPORTS_ENDPOINT}/sessions/pending`),

    getPendingTerminations: async ({limit = 20, offset = 0, ...query}) => await api.get(`${IMPORTS_ENDPOINT}/pending_terminations/`, null, {isCancelable: true, params: {limit, offset, ...query}}),

    getPendingMigrations: async ({limit = 20, offset = 0, ...query}) => await api.get(`${IMPORTS_ENDPOINT}/pending_migrations/`, null, {isCancelable: true, params: {limit, offset, ...query}}),

    updatePendingTerminationsNotes: async ({query, companyMetadataId}) => await api.put(`${IMPORTS_ENDPOINT}/pending_terminations/pending_termination_notes/`, query, {params: {company_metadata_id: companyMetadataId}}),

    getPendingTerminationsMembers: async ({alias, limit = 20, offset = 0, ...query}) => await api.get(`${IMPORTS_ENDPOINT}/pending_terminations/${alias}/members`, null, {params: {limit, offset, ...query}}),

    getPendingMigrationsMembers: async ({alias, limit = 20, offset = 0, ...query}) => await api.get(`${IMPORTS_ENDPOINT}/pending_migrations/${alias}/members`, null, {params: {limit, offset, arrayFormat: 'none', ...query}}),

    getVerificationReport: async ({session_id}) => await api.get(`${IMPORTS_ENDPOINT}/verification/`, null, {params: {session_id}}),

    getVerificationReportCardLinks: async ({limit = 20, offset = 0, ...query}) => await api.get(`${IMPORTS_ENDPOINT}/verification/card-links`, null, {params: {limit, offset, ...query}}),

    updateVerificationReportCardLinks: async ({session_id, ...query}) => await api.patch(`${IMPORTS_ENDPOINT}/verification/card-link`, query, {params: {session_id}}),

    updateVerificationReportEmailsMatched: async ({session_id, ...query}) => await api.patch(`${IMPORTS_ENDPOINT}/verification/emails-matched-confirmation`, query, {params: {session_id}}),

    getProcedures: async ({query, ids = [], isLoader = false}) => {
        const stringifiedIds = stringifyQueryParams({id: ids, arrayFormat: 'none'});

        return await api.get(`${PROVIDER_BASKET_ENDPOINT}/procedure?${stringifiedIds}`, null, {params: {query}, isLoader, isCancelable: true});
    },

    getSpecialties: async ({query, ids, isLoader = false}) => {
        const stringifiedIds = stringifyQueryParams({id: ids, arrayFormat: 'none'});

        return await api.get(`${PROVIDER_BASKET_ENDPOINT}/specialty?${stringifiedIds}`, null, {params: {query}, isLoader, isCancelable: true});
    }
});

export {apiServices as testableApiServices};
export default apiServices(api);
