import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const Layout = ({children, className = ''}) => <div className={`reporting-layout ${className}`}>{children}</div>;

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};

export {Layout as TestableLayout};
export default React.memo(Layout);
