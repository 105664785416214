import React from 'react';
import PropTypes from 'prop-types';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import './index.scss';

const CLASS_NAME_BY_TYPE = {
    [TEXT_TYPES.body]: 'pdf-text_body',
    [TEXT_TYPES.bodyBold]: 'pdf-text_body-bold',
    [TEXT_TYPES.helper]: 'pdf-text_helper',
    [TEXT_TYPES.helperBold]: 'pdf-text_helper-bold',
    [TEXT_TYPES.caption]: 'pdf-text_caption'
};

const PdfText = ({children, className = '', type = TEXT_TYPES.body, ...props}) => {
    const classNameByType = CLASS_NAME_BY_TYPE[type];

    return <Text type={type} {...props} className={`pdf-text ${className} ${classNameByType}`}>{children}</Text>;
};

PdfText.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    type: PropTypes.string,
    isInline: PropTypes.bool
};

export {PdfText as TestablePdfText};
export default PdfText;
