import React from 'react';
import PropTypes from 'prop-types';
import PdfText from '../PdfText';
import './index.scss';

const PdfTable = ({className = '', columns = [], data = []}) => {
    return (
        <table className={`pdf-table ${className}`}>
            <tbody>
                <tr className='pdf-table-row pdf-table-header'>
                    {columns.map(({header, width = 'auto', textAlign = 'initial'}) => (
                        <th key={header} className='pdf-table-row__cell pdf-table-header__cell' style={{width, textAlign}}>
                            <PdfText type='helper-bold'>{header}</PdfText>
                        </th>
                    ))}
                </tr>

                {data.map((dataItem, index) => (
                    <tr key={index} className='pdf-table-row'>
                        {columns.map(({accessor, width = 'auto', textAlign = 'initial'}) => (
                            <td key={accessor} className='pdf-table-row__cell' style={{width, textAlign}}>
                                <PdfText type='helper'>{dataItem[accessor]}</PdfText>
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

PdfTable.propTypes = {
    className: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.shape({
        header: PropTypes.string.isRequired,
        accessor: PropTypes.string.isRequired,
        width: PropTypes.string,
        textAlign: PropTypes.string
    })),
    data: PropTypes.arrayOf(PropTypes.shape({}))
};

export {PdfTable as TestablePdfTable};
export default PdfTable;
