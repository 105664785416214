import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import classnames from 'classnames';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import DropdownList, {DROPDOWN_LIST_ALIGNING} from '@frontend/ui-kit/Components/DropdownList';
import Text from '@frontend/ui-kit/Components/Text';
import {requestTimelineStepUpdating} from '../../../actions/general';
import {getIsSuperAdmin} from '../../../selectors/general';
import {STEP_ALIASES, TIMELINE_STEP_STATUSES} from '../../../constants';
import {getEqual} from '../../../utils';
import {TIMELINE_STATUS_OPTIONS} from '../../../options';
import './index.scss';

const ICON_TYPE_BY_STATUS = {
    [TIMELINE_STEP_STATUSES.completed]: ICON_TYPES.checkCircleFull,
    [TIMELINE_STEP_STATUSES.incompleted]: ICON_TYPES.alertCircleFull
};

const HorizontalTimeline = ({className = '', steps, parentAlias, isEditable}) => {
    const dispatch = useDispatch();
    const isSuperAdmin = useSelector(getIsSuperAdmin);

    const getStep = step => {
        const {id, status, title: label} = step;
        const iconType = ICON_TYPE_BY_STATUS[status];
        const isReviewStep = [STEP_ALIASES.benefitsReview, STEP_ALIASES.eligibilityReview].some(getEqual(parentAlias));
        const isStepEditable = isEditable && (isReviewStep || isSuperAdmin);
        const onDropdownListChange = status => dispatch(requestTimelineStepUpdating(id, {status}));
        const dropdownListProps = {
            options: TIMELINE_STATUS_OPTIONS,
            value: status,
            renderDropdownInitiator: () => <Icon className={`horizontal-timeline-step__icon horizontal-timeline-step__icon_${iconType}`} type={iconType}/>,
            aligning: DROPDOWN_LIST_ALIGNING.center,
            title: label,
            onChange: onDropdownListChange,
            className: 'horizontal-timeline-step__dropdown-list'
        };

        return (
            <div key={id} className={`horizontal-timeline-step horizontal-timeline-step_${status}`}>
                <div className='horizontal-timeline-step__circle'>
                    {isStepEditable ? <DropdownList {...dropdownListProps}/> : <Icon className={`horizontal-timeline-step__icon horizontal-timeline-step__icon_${iconType}`} type={iconType}/>}
                </div>

                <Text className='horizontal-timeline-step__label'>{label}</Text>
            </div>
        );
    };

    return (
        <div className={classnames('horizontal-timeline', className)}>
            {steps.map(getStep)}
        </div>
    );
};

HorizontalTimeline.propTypes = {
    className: PropTypes.string,
    isEditable: PropTypes.bool,
    parentAlias: PropTypes.string,
    steps: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        status: PropTypes.string,
        title: PropTypes.string
    })).isRequired
};

export default HorizontalTimeline;
