import React from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import classnames from 'classnames';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Link from '@frontend/ui-kit/Components/Link';
import './index.scss';

const BreadCrumbs = ({startsAfter = '/', routesMapper, className}) => {
    const {search, pathname} = useLocation();
    const searchObject = new URLSearchParams(search);
    const breadcrumbs = pathname
        .replace(startsAfter, '')
        .substring(1)
        .split('/')
        .reduce((acc, breadcrumb) => {
            const basePath = acc.length ? acc.at(-1) : startsAfter;
            const route = `${basePath}/${breadcrumb}`;

            return [...acc, route];
        }, [])
        .filter((breadcrumb, index) => !routesMapper[index].skip);

    const getBreadcrumb = (breadcrumb, index) => {
        const mapperWithoutSkipped = routesMapper.filter(({skip}) => !skip);
        const {displayName, name} = mapperWithoutSkipped[index];
        const formattedName = displayName
            ? searchObject.get(displayName) || breadcrumb.split('/').at(-1)
            : name;
        const isLast = index === breadcrumbs.length - 1;
        const link = `${breadcrumb}${search}`;

        return isLast
            ? <Text key={formattedName} type={TEXT_TYPES.helperBold}>{formattedName}</Text>
            : (
                <React.Fragment key={formattedName}>
                    <Link href={link}>
                        <Text type={TEXT_TYPES.helperBold}>{formattedName}</Text>
                    </Link>
                    <span> / </span>
                </React.Fragment>
            );
    };

    return <div className={classnames('breadcrumbs mb-20', className)}>{breadcrumbs.map(getBreadcrumb)}</div>;
};

BreadCrumbs.propTypes = {
    className: PropTypes.string,
    routesMapper: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        displayName: PropTypes.string,
        skip: PropTypes.bool
    })).isRequired,
    startsAfter: PropTypes.string
};

export {BreadCrumbs as TestableBreadCrumbs};
export default BreadCrumbs;
