import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {requestReportDownloading} from '../../../actions/reporting';
import './index.scss';

const DownloadReportButton = ({dashboardId, filters}) => {
    const dispatch = useDispatch();
    const onDownload = useCallback(() => {
        dispatch(requestReportDownloading(dashboardId, filters));
    }, [dashboardId, filters, dispatch]);

    return (
        <Button className='download-report-button'
            disabled={!filters}
            iconLeft={<Icon type={ICON_TYPES.download}/>}
            onClick={onDownload}>
            Download Page
        </Button>
    );
};

DownloadReportButton.propTypes = {
    dashboardId: PropTypes.number,
    filters: PropTypes.shape({})
};

export {DownloadReportButton as TestableDownloadReportButton};
export default React.memo(DownloadReportButton);
