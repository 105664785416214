import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import ReportingPagesTabs from '../../../Components/Reporting/ReportingPagesTabs';
import MemberActivationReporting from '../../../Components/Reporting/MemberActivation';

const MemberActivation = () => {
    return (
        <React.Fragment>
            <Row>
                <Column sm={9}>
                    <PageHeader title='Data Insights Dashboard'/>
                </Column>
            </Row>

            <ReportingPagesTabs/>

            <MemberActivationReporting/>
        </React.Fragment>
    );
};

export {MemberActivation as TestableMemberActivation};
export default React.memo(MemberActivation);
