import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Separator from '@frontend/ui-kit/Components/Separator';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import './index.scss';

const LABEL_MODIFIER_BY_TYPE = {
    primary: STICKER_TYPES.primary,
    secondary: STICKER_TYPES.secondary,
    success: STICKER_TYPES.success,
    danger: STICKER_TYPES.danger
};

const SidebarNavigationGroup = ({title, icon, label, labelType = 'primary', children, isOpenedByDefault = false}) => {
    const [isOpened, setIsOpened] = useState(isOpenedByDefault || false);
    const className = `sidebar-navigation-group ${isOpened ? 'sidebar-navigation-group_opened' : ''}`;

    const onToggle = () => setIsOpened(isOpened => !isOpened);

    return (
        <div data-testid='sidebar-navigation-group' className={className}>
            <div data-testid='sidebar-navigation-group-header' className='group-header' onClick={onToggle}>
                <Icon type={icon} className='group-header__icon'/>

                <div className='group-header-title'>
                    <div>{title}</div>
                    {label && <Sticker className='group-header-title__label' type={LABEL_MODIFIER_BY_TYPE[labelType]}>{label}</Sticker>}
                </div>

                <Icon type={ICON_TYPES.chevronDown} className='group-header__indicator'/>
            </div>

            <div className='group-navigation-items'>{children}</div>

            <Separator className='sidebar-navigation-group__separator'/>
        </div>
    );
};

SidebarNavigationGroup.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelType: PropTypes.string,
    children: PropTypes.node,
    isOpenedByDefault: PropTypes.bool
};

export {SidebarNavigationGroup as TestableSidebarNavigationGroup};
export default SidebarNavigationGroup;
