import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import ReportingPagesTabs from '../../../Components/Reporting/ReportingPagesTabs';
import CostContainmentReporting from '../../../Components/Reporting/CostContainment';

const CostContainment = () => {
    return (
        <React.Fragment>
            <Row>
                <Column sm>
                    <PageHeader title='Data Insights Dashboard'/>
                </Column>
            </Row>

            <ReportingPagesTabs/>

            <CostContainmentReporting/>
        </React.Fragment>
    );
};

export {CostContainment as TestableCostContainment};
export default React.memo(CostContainment);
