import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import ReportingPagesTabs from '../../../Components/Reporting/ReportingPagesTabs';
import BookOfBusinessReporting from '../../../Components/Reporting/BookOfBusiness';

const BookOfBusiness = () => {
    return (
        <React.Fragment>
            <Row>
                <Column sm>
                    <PageHeader title='Data Insights Dashboard'/>
                </Column>
            </Row>

            <ReportingPagesTabs/>

            <BookOfBusinessReporting/>
        </React.Fragment>
    );
};

export {BookOfBusiness as TestableBookOfBusiness};
export default React.memo(BookOfBusiness);
