import React, {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Table from '@frontend/ui-kit/Components/Table';
import Text from '@frontend/ui-kit/Components/Text';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Layout from '../Layout';
import {requestMembers, requestMembersReport} from '../../../actions/reporting';
import {getReportingInfo} from '../../../selectors/reporting';
import {pipe, getItemKeyValue, toCapitalize, formatPhone, formatDate} from '../../../utils';

const FILTER_DESCRIPTION = `
    Search a Member's name to see if they have activated their HealthJoy account.
    An employee is activated when they have registered for their HealthJoy account online or by calling our concierge team.
`;

const MemberActivation = () => {
    const [table, setTable] = useState({columns: [], unitCount: 0});
    const reportingInfo = useSelector(getReportingInfo);
    const dispatch = useDispatch();

    const onDownloadActivationReport = () => dispatch(requestMembersReport());

    const getTableColumns = ({isLocationShowed}) => {
        return [
            {Header: 'First Name', accessor: 'first_name', minWidth: 160, sticky: 'left'},
            {Header: 'Last Name', accessor: 'last_name', minWidth: 160},
            {Header: 'Gender', accessor: 'gender', width: 130, Cell: pipe(getItemKeyValue('value'), toCapitalize)},
            {Header: 'Birthday', accessor: 'birthday', width: 140, Cell: ({value}) => formatDate(value, 'MM/dd/yyyy')},
            {Header: 'Email Address', accessor: 'email', minWidth: 250},
            {Header: 'Phone Number', accessor: 'phone_number', width: 200, Cell: pipe(getItemKeyValue('value'), formatPhone)},
            {Header: 'State', accessor: 'state', minWidth: 130},
            {Header: 'Zip Code', accessor: 'zip_code', width: 150},
            {Header: 'Division', accessor: 'location', minWidth: 150, isHidden: !isLocationShowed},
            {
                Header: 'Activation Status',
                accessor: 'activated',
                width: 200,
                Cell: ({value}) => value ? formatDate(value, 'MM/dd/yyyy') : 'Not Activated'
            }
        ];
    };

    const onFetchData = useCallback(async ({pageIndex, pageSize, sortBy, orderBy, filterBy}) => {
        const params = {sortBy, orderBy, query: filterBy, limit: pageSize, offset: pageIndex * pageSize};
        const {count: unitCount, isLocationShowed} = await dispatch(requestMembers(params));

        setTable({unitCount, columns: getTableColumns({isLocationShowed})});
    }, [dispatch]);

    const {members} = reportingInfo.member_activation || {};

    const tableProps = {
        title: 'Member Activation Status',
        data: members,
        filterDescription: FILTER_DESCRIPTION,
        ActionBar: <Button type={BUTTON_TYPES.tertiary} onClick={onDownloadActivationReport}>DOWNLOAD ACTIVATION REPORT</Button>,
        onFetchData,
        ...table
    };

    return (
        <Layout>
            <div className='reporting reporting_member-details'>
                <Heading data-testid='member-activation-title' className='section-heading' type={HEADING_TYPES['2']}>Member Activation List</Heading>
                <Text className='mt-5 mb-12'>
                    View your full list of HealthJoy-eligible employees in the table below or by clicking the download button.
                    The Activation Status column tells you who is Not Activated, so you know who to contact to boost activation.
                    Activated employees have their activation date in that column.
                </Text>

                <ContentSection>
                    <Table {...tableProps}/>
                </ContentSection>
            </div>

            <Text className='reporting-disclaimer'>
                Data on this page is updated when an employee is invited or activates their HealthJoy account.
            </Text>
        </Layout>
    );
};

export {MemberActivation as TestableMemberActivation};
export default MemberActivation;
