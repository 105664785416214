import React from 'react';
import PropTypes from 'prop-types';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import AnchorTabs from '../../shared/AnchorTabs';
import MarketingSection from '../MarketingSection';
import {DASHBOARD_LAYOUT_SELECTOR} from '../../../constants';
import './index.scss';

const SECTIONS_COLORS = ['cyan', 'scrub', 'yellow', 'pink', 'indigo'];

const getMarketingSection = (section, index) => {
    const colorIndex = index % SECTIONS_COLORS.length; // FYI: following SECTIONS_COLORS order, then repeats from the beginning (Vlad, 28.07.20)
    const color = SECTIONS_COLORS[colorIndex];
    const {id: sectionId} = section;
    const props = {...section, color};

    return (
        <div className='anchor-section' id={sectionId} key={sectionId}>
            <MarketingSection {...props}/>
        </div>
    );
};

const MarketingPage = ({title, subtitle, sections = []}) => {
    const scrollContainer = document.querySelector(DASHBOARD_LAYOUT_SELECTOR);
    const tabs = sections.map(({id, title}) => ({id, name: title}));
    const areTabs = tabs.length > 1;

    return (
        <div className='marketing-page'>
            <Heading type={HEADING_TYPES['2']}>{title}</Heading>
            <Text className='marketing-page__subtitle'>{subtitle}</Text>

            {areTabs && <AnchorTabs tabs={tabs} scrollContainer={scrollContainer} isSticky/>}

            <div className='marketing-sections'>
                {sections.map(getMarketingSection)}
            </div>
        </div>
    );
};

MarketingPage.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        page_id: PropTypes.number,
        order: PropTypes.number,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        cards: PropTypes.array,
        is_published: PropTypes.bool
    }))
};

export {MarketingPage as TestableMarketingPage};
export default React.memo(MarketingPage);
