import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import classnames from 'classnames';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Tooltip, {TOOLTIP_DIRECTIONS} from '@frontend/ui-kit/Components/Tooltip';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import VideoPopup from '../VideoPopup';
import AnnouncementPopup from '../AnnouncementPopup';
import withPopup from '../../../HOC/withPopup';
import {getProfileInfo} from '../../../selectors/general';
import {setUserOnboarded} from '../../../actions/general';
import {getItemKeyValue} from '../../../utils';
import './index.scss';

const POPUP_ID = 'VIDEO_POPUP';
const TOOLTIP_Z_INDEX = 9999999; // FYI: should be less than popups' one (16.01.2024, Oleh);

const OnboardingTooltip = ({onboardingInfo, openPopup}) => {
    const dispatch = useDispatch();
    const [isOpened, setIsOpened] = useState(false);
    const {type, isNew, isSkipOnLoad, videoId, slides, thumbnail, tooltipTitle, tooltipDescription, popupTitle, popupDescription} = onboardingInfo;
    const {user_onboarded: userOnboarded = []} = useSelector(getProfileInfo) ?? {};

    const getIsUserOnboarded = () => userOnboarded.map(getItemKeyValue('onboarded_type')).includes(type);

    const onDismiss = () => {
        const isUserOnboarded = getIsUserOnboarded();

        if (!isUserOnboarded) {
            dispatch(setUserOnboarded(type));
        }

        setIsOpened(false);
    };

    const onOpenPopup = () => {
        const title = popupTitle ?? tooltipTitle;
        const description = popupDescription ?? tooltipDescription;

        const children = videoId
            ? <VideoPopup title={title} videoId={videoId}/>
            : <AnnouncementPopup title={title} description={description} slides={slides}/>;

        onDismiss();
        openPopup({type: POPUP_TYPES.simple, children});
    };

    useEffect(() => {
        if (isSkipOnLoad) {
            return false;
        }

        const isUserOnboarded = getIsUserOnboarded();

        setIsOpened(!isUserOnboarded);
    }, [userOnboarded]);

    useEffect(() => {
        const isUserOnboarded = getIsUserOnboarded();

        if (!isNew || isUserOnboarded) {
            return;
        }

        onOpenPopup();
    }, []);

    const tooltipContent = (
        <div className='onboarding-tooltip'>
            <Text type={TEXT_TYPES.bodyBold} className='onboarding-tooltip-video__title'>{tooltipTitle}</Text>

            {videoId && (
                <div className='onboarding-tooltip-video__thumbnail' onClick={onOpenPopup}>
                    <img src={thumbnail} className='onboarding-tooltip-video__thumbnail-image' alt=''/>
                    <div className='onboarding-tooltip-video__play-button'/>
                </div>
            )}
            {!videoId && (
                <div className='onboarding-tooltip-video__thumbnail'>
                    <img src={thumbnail} className='onboarding-tooltip-video__thumbnail-image' alt=''/>
                </div>
            )}
            <Text type={TEXT_TYPES.helper} className='onboarding-tooltip-video__description'>{tooltipDescription}</Text>
            <div>
                <Button className='onboarding-tooltip-actions__link' type={BUTTON_TYPES.tertiary} onClick={onOpenPopup}>
                    {videoId ? 'Watch the Video' : 'Learn more'}
                </Button>
                <Button className='onboarding-tooltip-actions__link' type={BUTTON_TYPES.tertiary} onClick={onDismiss}>Dismiss</Button>
            </div>
        </div>
    );

    return (
        <div className='onboarding-tooltip'>
            <div className={classnames('onboarding-tooltip-popup__overlay', {'onboarding-tooltip-popup__overlay_opened': isOpened})} onClick={() => setIsOpened(false)}/>
            <Tooltip content={tooltipContent} direction={TOOLTIP_DIRECTIONS.right} isOpened={isOpened} zIndex={TOOLTIP_Z_INDEX}>
                {isNew && (
                    <Sticker className='onboarding-tooltip-info__sticker' type={STICKER_TYPES.primary} iconType={ICON_TYPES.celebration} onClick={() => setIsOpened(true)}>
                        <Text>New</Text>
                    </Sticker>
                )}
                {!isNew && <Icon className='onboarding-tooltip-info__icon' type={ICON_TYPES.info} onClick={() => setIsOpened(true)}/>}
            </Tooltip>
        </div>
    );
};

OnboardingTooltip.propTypes = {
    onboardingInfo: PropTypes.shape({
        type: PropTypes.string,
        videoId: PropTypes.string,
        slides: PropTypes.arrayOf(PropTypes.node),
        tooltipTitle: PropTypes.node,
        tooltipDescription: PropTypes.node,
        isNew: PropTypes.bool,
        popupTitle: PropTypes.node,
        popupDescription: PropTypes.node,
        thumbnail: PropTypes.string,
        isSkipOnLoad: PropTypes.bool
    }).isRequired,
    openPopup: PropTypes.func.isRequired
};

export {OnboardingTooltip as TestableOnboardingTooltip};
export default withPopup(POPUP_ID)(OnboardingTooltip);
