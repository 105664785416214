import React from 'react';
import PropTypes from 'prop-types';
import Text from '@frontend/ui-kit/Components/Text';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import './index.scss';

const ProgressButton = ({completed, disabled, onClick, children, className = ''}) => {
    const completedClassName = completed ? 'progress-button_completed' : '';
    const defaultIconType = completed ? ICON_TYPES.confirm : ICON_TYPES.circlePlus;
    const iconProps = {
        type: disabled ? ICON_TYPES.circleMinus : defaultIconType,
        className: 'progress-button__icon'
    };

    return (
        <button type='button' className={`progress-button ${completedClassName} ${className}`} disabled={disabled} onClick={onClick}>
            <Icon {...iconProps}/>
            <Text className='progress-button__text'>{children}</Text>
        </button>
    );
};

ProgressButton.propTypes = {
    completed: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func
};

export {ProgressButton as TestableProgressButton};
export default React.memo(ProgressButton);
