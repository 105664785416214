import React from 'react';
import PropTypes from 'prop-types';
import FileUploader from '../../shared/FileUploader';
import CopyableField from '../CopyableField';
import {ACCEPTABLE_TEXT_FORMATS} from '../../../constants';

const DocumentUploader = ({isReadonly, label = 'Document:', isMultiple = true}) => {
    return (
        <div className='document-uploader'>
            <CopyableField name='documents'>
                {props => (
                    <FileUploader {...props}
                        readonly={isReadonly}
                        isMultiple={isMultiple}
                        accept={ACCEPTABLE_TEXT_FORMATS}
                        label={label}/>
                )}
            </CopyableField>
        </div>
    );
};

DocumentUploader.propTypes = {
    isReadonly: PropTypes.bool,
    label: PropTypes.string,
    isMultiple: PropTypes.bool
};

export {DocumentUploader as TestableDocumentUploader};
export default React.memo(DocumentUploader);
