import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const Separator = ({className = ''}) => <div className={`pdf-separator ${className}`}/>;

Separator.propTypes = {
    className: PropTypes.string
};

export {Separator as TestableSeparator};
export default React.memo(Separator);
