import React from 'react';
import PropTypes from 'prop-types';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import {IS_SAFARI_BROWSER, YOUTUBE_EMBED_URL} from '../../../constants';

const VideoPopup = ({title, videoId, isAutoplay = false, height = 420, width = 750, options}) => {
    const params = options ? Object.entries(options).map(([key, value]) => `${key}='${value}'`).join(' ') : '';
    const embedCode = `
        <iframe width='${width}'
            height='${height}'
            src='${YOUTUBE_EMBED_URL}/${videoId}?start=0'
            frameborder='0'
            allow='accelerometer; ${isAutoplay ? 'autoplay;' : ''} clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            ${!IS_SAFARI_BROWSER ? 'allowFullscreen' : ''}
            ${params}/>
    `;

    return (
        <PopupContent title={title}>
            <div dangerouslySetInnerHTML={{__html: embedCode}}/>
        </PopupContent>
    );
};

VideoPopup.propTypes = {
    title: PropTypes.string.isRequired,
    videoId: PropTypes.string.isRequired,
    isAutoplay: PropTypes.bool,
    height: PropTypes.number,
    width: PropTypes.number,
    options: PropTypes.shape({})
};

export {VideoPopup as TestableVideoPopup};
export default React.memo(VideoPopup);
