import React from 'react';
import PropTypes from 'prop-types';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Slider, {SwiperSlide} from '@frontend/ui-kit/Components/Slider';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import HJLogo from '../../../static/images/hjLogoPurple.svg';
import './index.scss';

const AnnouncementPopup = ({title, description, slides = []}) => {
    const props = {className: 'announcement-popup', bodyClassName: 'announcement-popup-body', title: <img className='announcement-popup__title-logo' src={HJLogo} alt=''/>};
    const sliderProps = {
        className: 'announcement-slider',
        navigationButtonClassName: 'announcement-slider__navigation-button',
        navigation: true,
        pagination: true,
        slides: slides.map((slide, index) => <SwiperSlide key={index}>{slide}</SwiperSlide>)
    };

    return (
        <PopupContent {...props}>
            <Heading className='mb-16' type={HEADING_TYPES['3']}>{title}</Heading>

            <Text className='mb-16'>{description}</Text>

            <Slider {...sliderProps}/>
        </PopupContent>
    );
};

AnnouncementPopup.propTypes = {
    title: PropTypes.node,
    description: PropTypes.node,
    slides: PropTypes.arrayOf(PropTypes.node)
};

export {AnnouncementPopup as TestableAnnouncementPopup};
export default AnnouncementPopup;
