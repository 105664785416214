import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import Input from '@frontend/ui-kit/Components/Input';
import Autocomplete from '@frontend/ui-kit/Components/Autocomplete';
import Textarea from '@frontend/ui-kit/Components/Textarea';
import Select from '@frontend/ui-kit/Components/Select';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Separator from '@frontend/ui-kit/Components/Separator';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import ArrowStepper from '../../shared/ArrowStepper';
import {Field, Form} from '../../shared/FormComponents';
import withUnsavedFormWarning from '../../../HOC/withUnsavedFormWarning';
import {getPlanManagerBenefitsCategories} from '../../../selectors/adminPortal';
import usePrevious from '../../../hooks/usePrevious';
import useFormState from '../../../hooks/useFormState';
import {FORMS, BENEFITS_CATEGORIES} from '../../../constants';
import {EXPLANATION_OPTIONS} from '../../../options';
import {compose, equal, generateUniqueId, getEqual, negateFunc, validateRequired} from '../../../utils';
import './index.scss';

/* istanbul ignore next */
const validate = types => values => {
    return ({
        type: validateRequired(values.type) || (types.some(getEqual(values.type.toLowerCase())) ? 'Benefit name already exists' : undefined)
    });
};

const HJBenefitPopup = props => {
    const {
        title,
        activeStep,
        stepsLength,
        isFormResettable,
        benefit = {},
        onDelete,
        onSave,
        onClose,
        onPrevStep,
        onNextStep,
        onWarnAboutUnsavedForm,
        existingTypes
    } = props;

    const {id: benefitId} = benefit;
    const prevBenefitId = usePrevious(benefitId);
    const [initialValues, setInitialValues] = useState({});
    const benefitsCategories = useSelector(getPlanManagerBenefitsCategories);
    const typeOptions = useMemo(() => {
        return benefitsCategories
            .map(({name}) => name)
            .sort()
            .map(name => ({label: name, value: name}));
    }, [benefitsCategories]);
    const {values} = useFormState();
    const preferredInNetworkDescription = values?.medtool_plan?.cost_share_variance?.location_preferred_in_network_description || 'Preferred In Network';
    const inNetworkDescription = values?.medtool_plan?.benefits_tab_name_1 || 'In Network';
    const outOfNetworkDescription = values?.medtool_plan?.benefits_tab_name_2 || 'Out of Network';
    const availableTypes = useMemo(() => existingTypes.filter(negateFunc(getEqual(initialValues.type))), [initialValues, existingTypes]);

    const onSubmit = useCallback(values => {
        onSave(values);
        // FYI: Use a unique id for correct resetting fields (Pasha, 17.01.2022)
        setInitialValues(isFormResettable ? {id: generateUniqueId()} : values);
    }, [isFormResettable, onSave]);

    useEffect(() => {
        // FYI: Initial values should be added for just created new HJ benefit without id (Pasha, 20.12.2021)
        if (!equal(benefitId, prevBenefitId) || !benefitId) {
            setInitialValues(benefit);
        }
    }, [benefit, benefitId, prevBenefitId]);

    const stepHandler = (isDirtyForm, callback) => {
        return isDirtyForm ? onWarnAboutUnsavedForm(callback, () => {}, FORMS.hjBenefit) : callback();
    };

    const onChangeCategory = (type = '', form) => {
        const {category} = benefitsCategories.find(getEqual(type.toLowerCase(), 'name')) ?? {};

        form.change('category', category ?? BENEFITS_CATEGORIES.notOrganizedBenefits);
    };

    return (
        <Form name={FORMS.hjBenefit} initialValues={initialValues} validate={validate(availableTypes)} onSubmit={onSubmit} isScrollToError={false}>
            {({handleSubmit, dirty: isDirtyForm, form, values}) => {
                const arrowStepperProps = {
                    activeStep,
                    stepsLength,
                    onPrevStep: () => stepHandler(isDirtyForm, onPrevStep),
                    onNextStep: () => stepHandler(isDirtyForm, onNextStep)
                };
                const closeHandler = () => stepHandler(isDirtyForm, onClose);

                const actionBar = (
                    <div className='hj-benefit-action-bar'>
                        {stepsLength && <ArrowStepper {...arrowStepperProps}/>}

                        <div className='hj-benefit-action-bar__buttons'>
                            <Button type={BUTTON_TYPES.secondary} onClick={closeHandler} className='hj-benefit-action-bar__button'>Cancel</Button>
                            {onDelete && <Button type={BUTTON_TYPES.destructive} onClick={onDelete} className='hj-benefit-action-bar__button'>Delete</Button>}
                            <Button type={BUTTON_TYPES.primary} onClick={handleSubmit} className='hj-benefit-action-bar__button'>Save</Button>
                        </div>
                    </div>
                );

                const customNetworkExplanation = EXPLANATION_OPTIONS.find(({label}) => label === 'Other');
                const preferredInNetworkExplanation = EXPLANATION_OPTIONS.find(({value}) => value === values?.preferred_in_network_explanation) || customNetworkExplanation;
                const inNetworkExplanation = EXPLANATION_OPTIONS.find(({value}) => value === values?.in_network_explanation) || customNetworkExplanation;
                const outOfNetworkExplanation = EXPLANATION_OPTIONS.find(({value}) => value === values?.out_of_network_explanation) || customNetworkExplanation;
                const onChangeExplanation = (field, value) => form.change(field, value);
                const selectPortalProps = {
                    menuPortalTarget: document.body,
                    styles: {menuPortal: base => ({...base, zIndex: 99999999})},
                    menuShouldBlockScroll: true
                };

                return (
                    <PopupContent title={title} actionBar={actionBar} isScrollableContent>
                        <form className='hj-benefit-popup-body'>
                            <Row>
                                <Column sm={4}>
                                    <Field name='type' onChange={onChangeCategory}>
                                        {({value, ...props}) => <Autocomplete {...props} value={value?.toLowerCase()} options={typeOptions} isCreatable className='hj-benefit-popup-body__label' label='Name'/>}
                                    </Field>
                                </Column>
                                <Column sm={4}>
                                    <Field name='category'>
                                        {props => <Input {...props} disabled className='hj-benefit-popup-body__label' label='Category'/>}
                                    </Field>
                                </Column>
                            </Row>

                            <Separator/>

                            <Row>
                                <Column sm={4}>
                                    <Field name='copay_preferred_in_network'>
                                        {props => <Textarea {...props} autoComplete='on' maxLength={255} className='hj-benefit-popup-body__label' label={`Copay (${preferredInNetworkDescription})`}/>}
                                    </Field>
                                </Column>
                                <Column sm={4}>
                                    <Field name='copay_in_network'>
                                        {props => <Textarea {...props} autoComplete='on' maxLength={255} className='hj-benefit-popup-body__label' label={`Copay (${inNetworkDescription})`}/>}
                                    </Field>
                                </Column>
                                <Column sm={4}>
                                    <Field name='copay_out_of_network'>
                                        {props => <Textarea {...props} autoComplete='on' maxLength={255} className='hj-benefit-popup-body__label' label={`Copay (${outOfNetworkDescription})`}/>}
                                    </Field>
                                </Column>
                            </Row>

                            <Separator/>

                            <Row>
                                <Column sm={4}>
                                    <Field name='coinsurance_preferred_in_network'>
                                        {props => <Textarea {...props} autoComplete='on' maxLength={255} className='hj-benefit-popup-body__label' label={`Coinsurance (${preferredInNetworkDescription})`}/>}
                                    </Field>
                                </Column>
                                <Column sm={4}>
                                    <Field name='coinsurance_in_network'>
                                        {props => <Textarea {...props} autoComplete='on' maxLength={255} className='hj-benefit-popup-body__label' label={`Coinsurance (${inNetworkDescription})`}/>}
                                    </Field>
                                </Column>
                                <Column sm={4}>
                                    <Field name='coinsurance_out_of_network'>
                                        {props => <Textarea {...props} autoComplete='on' maxLength={255} className='hj-benefit-popup-body__label' label={`Coinsurance (${outOfNetworkDescription})`}/>}
                                    </Field>
                                </Column>
                            </Row>

                            <Separator/>

                            <Row>
                                <Column sm={4}>
                                    <Select options={EXPLANATION_OPTIONS}
                                        value={preferredInNetworkExplanation.value}
                                        onChange={value => onChangeExplanation('preferred_in_network_explanation', value)}
                                        className='hj-benefit-popup-body__label'
                                        label={`Explanation (${preferredInNetworkDescription})`}
                                        maxMenuHeight={200}
                                        {...selectPortalProps}/>
                                </Column>
                                <Column sm={4}>
                                    <Select options={EXPLANATION_OPTIONS}
                                        value={inNetworkExplanation.value}
                                        onChange={value => onChangeExplanation('in_network_explanation', value)}
                                        className='hj-benefit-popup-body__label'
                                        label={`Explanation (${inNetworkDescription})`}
                                        maxMenuHeight={200}
                                        {...selectPortalProps}/>
                                </Column>
                                <Column sm={4}>
                                    <Select options={EXPLANATION_OPTIONS}
                                        value={outOfNetworkExplanation.value}
                                        onChange={value => onChangeExplanation('out_of_network_explanation', value)}
                                        className='hj-benefit-popup-body__label'
                                        label={`Explanation (${outOfNetworkDescription})`}
                                        maxMenuHeight={200}
                                        {...selectPortalProps}/>
                                </Column>
                            </Row>

                            {preferredInNetworkExplanation.label === customNetworkExplanation.label && (
                                <Field name='preferred_in_network_explanation'>
                                    {props => <Textarea {...props} autoComplete='on' maxLength={255} className='hj-benefit-popup-body__explanation-label' label={`Explanation - Other (${preferredInNetworkDescription})`}/>}
                                </Field>
                            )}

                            {inNetworkExplanation.label === customNetworkExplanation.label && (
                                <Field name='in_network_explanation'>
                                    {props => <Textarea {...props} autoComplete='on' maxLength={255} className='hj-benefit-popup-body__explanation-label' label={`Explanation - Other (${inNetworkDescription})`}/>}
                                </Field>
                            )}

                            {outOfNetworkExplanation.label === customNetworkExplanation.label && (
                                <Field name='out_of_network_explanation'>
                                    {props => <Textarea {...props} autoComplete='on' maxLength={255} className='hj-benefit-popup-body__explanation-label' label={`Explanation - Other (${outOfNetworkDescription})`}/>}
                                </Field>
                            )}
                        </form>
                    </PopupContent>
                );
            }}
        </Form>
    );
};

HJBenefitPopup.propTypes = {
    title: PropTypes.string,
    activeStep: PropTypes.number,
    stepsLength: PropTypes.number,
    isFormResettable: PropTypes.bool,
    benefit: PropTypes.shape({
        id: PropTypes.string
    }),
    existingTypes: PropTypes.arrayOf(PropTypes.string),
    onDelete: PropTypes.func,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    requestPlanManagerBenefitsCategories: PropTypes.func,
    onPrevStep: PropTypes.func,
    onNextStep: PropTypes.func,
    onWarnAboutUnsavedForm: PropTypes.func
};

export {HJBenefitPopup as TestableHJBenefitPopup};
export default compose(
    withUnsavedFormWarning,
    React.memo
)(HJBenefitPopup);
