import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import OnboardingTooltip from '../OnboardingTooltip';
import {getRoutesUnavailabilityMap} from '../../../selectors/general';
import {getOnboardingInfo} from '../../../helpers';
import './index.scss';

const PageHeader = ({title, caption, children, match}) => {
    const routesUnavailabilityMap = useSelector(getRoutesUnavailabilityMap);
    const onboardingInfo = getOnboardingInfo({routesUnavailabilityMap})[match.path];

    return (
        <Row>
            <Column sm={10}>
                <div className='page-header'>
                    <Heading data-testid='page-header-title' className='page-header-title' type={HEADING_TYPES['1']}>
                        {title}
                        {onboardingInfo && <OnboardingTooltip onboardingInfo={onboardingInfo}/>}
                    </Heading>
                    <Text>{caption}</Text>
                </div>
            </Column>
            <Column sm={2}>
                {children}
            </Column>
        </Row>
    );
};

PageHeader.propTypes = {
    title: PropTypes.string,
    match: PropTypes.shape({path: PropTypes.string}),
    children: PropTypes.node,
    caption: PropTypes.node
};

export {PageHeader as TestablePageHeader};
export default withRouter(PageHeader);
