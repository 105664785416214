import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import classnames from 'classnames';
import Link from '@frontend/ui-kit/Components/Link';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import {replaceTo} from '../../../actions/general';
import {equal, throttle} from '../../../utils';
import './index.scss';

const ADDITIONAL_TOP_OFFSET = 40;

const AnchorTabs = props => {
    const {
        isSticky = false,
        className = '',
        tabs = [],
        scrollContainer = window
    } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const anchorTabsRef = useRef();
    const [activeTabId, setActiveTabId] = useState(tabs[0]?.id || null);
    const [sectionOffsetTop, setSectionOffsetTop] = useState(null);

    const scrollToSection = id => {
        const anchorElement = document.getElementById(id);

        if (!anchorElement) {
            return;
        }

        const top = anchorElement.offsetTop - sectionOffsetTop;

        scrollContainer.scrollTo({top, behavior: 'smooth'});
    };

    const onScroll = () => {
        const {scrollTop, scrollHeight, clientHeight} = equal(scrollContainer, window) ? document.documentElement : scrollContainer; // FYI: For correct calculation scroll position we need get scroll offset top value in main scroll. But window doesn't have right scrollTop method like DOM element so we use document.documentElement (12.03.2020, Pasha)
        const {offsetHeight: anchorTabsHeight} = anchorTabsRef.current;
        const sectionOffsetTop = isSticky ? anchorTabsHeight + ADDITIONAL_TOP_OFFSET : 0;
        const scrollTopOffset = scrollTop + sectionOffsetTop;

        tabs.some(({id}, index, tabsArr) => {
            const tabElement = document.getElementById(id);

            if (!tabElement) {
                return;
            }

            const {offsetTop, offsetHeight} = tabElement;
            const isLastTabActive = equal(Math.round(scrollTop + clientHeight), scrollHeight);
            const isCurrentTabActive = offsetTop <= scrollTopOffset && offsetTop + offsetHeight > scrollTopOffset;
            const isActiveTabFound = isLastTabActive || isCurrentTabActive;

            if (isActiveTabFound) {
                const activeTabId = (isLastTabActive && tabsArr[tabsArr.length - 1].id) || id;

                dispatch(replaceTo(`#${activeTabId}`));
                setActiveTabId(activeTabId);
            }

            return isActiveTabFound;
        });
    };
    const throttleOnScroll = throttle(onScroll);

    useEffect(() => {
        const {hash: initialHash} = location;
        const {id: initialTabId} = tabs[0] || {};
        const {offsetHeight: anchorTabsHeight} = anchorTabsRef.current;
        const sectionOffsetTop = isSticky ? anchorTabsHeight + ADDITIONAL_TOP_OFFSET : 0;

        if (initialHash) {
            scrollToSection(initialHash.slice(1));
        }

        scrollContainer.addEventListener('scroll', throttleOnScroll, {passive: true});
        setActiveTabId(initialTabId);
        setSectionOffsetTop(sectionOffsetTop);

        return () => {
            scrollContainer.removeEventListener('scroll', throttleOnScroll, false);
        };
    }, []);

    const onTabClick = id => event => {
        event.preventDefault();

        scrollToSection(id);
    };

    const getTab = ({id, name}) => {
        const isActiveTab = equal(`${activeTabId}`, `${id}`);
        const linkClassName = classnames('tabs-link', {'tabs-link_active': isActiveTab});

        return (
            <li key={id} className='tabs-list__item'>
                <Link href={`#${id}`} onClick={onTabClick(id)} className={linkClassName}>{name}</Link>
            </li>
        );
    };

    const tabsClassName = classnames('anchor-tabs', className, {'anchor-tabs_sticky': isSticky});

    return (
        <ContentSection ref={anchorTabsRef} className={tabsClassName}>
            <ul className='tabs-list'>
                {tabs.map(getTab)}
            </ul>
        </ContentSection>
    );
};

AnchorTabs.propTypes = {
    isSticky: PropTypes.bool,
    className: PropTypes.string,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string
    })),
    scrollContainer: PropTypes.oneOfType([
        PropTypes.instanceOf(Element),
        PropTypes.shape({current: PropTypes.instanceOf(window)})
    ])
};

export {AnchorTabs as TestableAnchorTabs};
export default AnchorTabs;
