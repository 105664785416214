import React from 'react';
import PropTypes from 'prop-types';
import PdfHeading from '../PdfHeading';
import './index.scss';

const PageContent = ({title, children, className = ''}) => (
    <React.Fragment>
        {title && <PdfHeading type={2}>{title}</PdfHeading>}
        <div className={`pdf-page-content ${className}`}>{children}</div>
    </React.Fragment>
);

PageContent.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    title: PropTypes.string
};

export {PageContent as TestablePageContent};
export default PageContent;
