import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import classnames from 'classnames';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Select from '@frontend/ui-kit/Components/Select';
import Input from '@frontend/ui-kit/Components/Input';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import AsyncAutocomplete from '@frontend/ui-kit/Components/AsyncAutocomplete';
import DatePicker from '@frontend/ui-kit/Components/DatePicker';
import {setMultipleChoiceChoices, setMultipleChoiceValues} from '../../../actions/adminPortal';
import {getMultipleChoiceChoices, getMultipleChoiceValues} from '../../../selectors/adminPortal';
import usePrevious from '../../../hooks/usePrevious';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import {negateFunc, getEqual, equal, isEmpty, isObject} from '../../../utils';
import {MULTIPLE_CHOICE_DEFAULT_NAME, MULTIPLE_CHOICE_TYPES} from '../../../constants';
import './index.scss';

const DATE_RANGE_PREFIX_FROM = '_from';
const DATE_RANGE_PREFIX_TO = '_to';

const getSelectedValues = values => Object
    .entries(values)
    .reduce((acc, [key, value]) => ({...acc, ...value && {[key]: value}}), {});

const MultipleChoice = props => {
    const {
        defaultChoicesCount = 1,
        maxChoicesCount = 3,
        choices = [],
        defaultValues = {},
        onChange,
        isVertical = false,
        name = MULTIPLE_CHOICE_DEFAULT_NAME
    } = props;

    const prevChoices = usePrevious(choices);
    const activeChoices = useSelector(state => getMultipleChoiceChoices(state, name));
    const chosenValues = useSelector(state => getMultipleChoiceValues(state, name));
    const dispatch = useDispatch();
    const onChangeDebounced = useDebouncedCallback(onChange);

    const setActiveChoices = useCallback(choices => dispatch(setMultipleChoiceChoices(choices, name)), [dispatch, name]);
    const setChosenValues = useCallback(values => dispatch(setMultipleChoiceValues(values, name)), [dispatch, name]);

    const getChoicesByDefaultValues = useCallback(() => choices.filter(({type}) => defaultValues[type]), [choices, defaultValues]);
    const getDefaultChoices = useCallback(() => choices.slice(0, defaultChoicesCount), [choices, defaultChoicesCount]);

    useEffect(() => {
        if (isEmpty(activeChoices)) {
            setActiveChoices(isEmpty(defaultValues) ? getDefaultChoices() : getChoicesByDefaultValues());
        }

        if (!isEmpty(defaultValues)) {
            setChosenValues(defaultValues);
        }
    }, []);

    useEffect(() => {
        if (prevChoices && !equal(prevChoices.length, choices.length)) {
            setActiveChoices(getDefaultChoices());
        }
    }, [choices, prevChoices, getDefaultChoices, setActiveChoices]);

    const getIsChoiceUnused = ({type}) => !activeChoices.some(getEqual(type, 'type'));

    const addChoice = () => {
        const unusedChoice = choices.find(getIsChoiceUnused);

        setActiveChoices([...activeChoices, unusedChoice]);
    };

    const removeChoice = type => () => {
        const filteredActiveChoices = activeChoices.filter(negateFunc(getEqual(type, 'type')));
        const {
            [type]: removedValue,
            [type + DATE_RANGE_PREFIX_FROM]: removedFromValue,
            [type + DATE_RANGE_PREFIX_TO]: removedToValue,
            ...updatedChosenValues
        } = chosenValues;

        setActiveChoices(filteredActiveChoices);
        setChosenValues(updatedChosenValues);

        if (isEmpty(updatedChosenValues)) { // FYI: we don't need to call onChange if no chosenValues after remove. (Pasha, 5.03.2021)
            return false;
        }

        onChangeDebounced(getSelectedValues(updatedChosenValues));
    };

    const onChangeChoiceType = previousType => newType => {
        const selectedChoice = choices.find(getEqual(newType, 'type'));
        const updatedActiveChoices = activeChoices.map(choice => equal(choice.type, previousType) ? selectedChoice : choice);
        const {
            [previousType]: previousValue,
            [previousType + DATE_RANGE_PREFIX_FROM]: previousFromValue,
            [previousType + DATE_RANGE_PREFIX_TO]: previousToValue,
            ...restChosenValues
        } = chosenValues;
        const updatedChosenValues = {...restChosenValues, [newType]: ''};

        setActiveChoices(updatedActiveChoices);
        setChosenValues(updatedChosenValues);
        onChangeDebounced(getSelectedValues(updatedChosenValues));
    };

    const onChangeChoice = choiceType => value => {
        const {[choiceType]: prevValue, ...restChosenValues} = chosenValues;
        const updatedChosenValues = {
            ...restChosenValues,
            ...isObject(value) ? value : {[choiceType]: value}
        };

        setChosenValues(updatedChosenValues);
        onChangeDebounced(getSelectedValues(updatedChosenValues));
    };

    const getChoiceType = ({choiceType, loadOptions, options = []}) => {
        if (equal(choiceType, MULTIPLE_CHOICE_TYPES.select) || options.length) {
            return MULTIPLE_CHOICE_TYPES.select;
        }
        if (equal(choiceType, MULTIPLE_CHOICE_TYPES.asyncAutocomplete) || loadOptions) {
            return MULTIPLE_CHOICE_TYPES.asyncAutocomplete;
        }
        if (equal(choiceType, MULTIPLE_CHOICE_TYPES.dateRange)) {
            return MULTIPLE_CHOICE_TYPES.dateRange;
        }
        if (equal(choiceType, MULTIPLE_CHOICE_TYPES.date)) {
            return MULTIPLE_CHOICE_TYPES.date;
        }
        return MULTIPLE_CHOICE_TYPES.input;
    };

    const getDateRange = ({type, isClearable = true}) => {
        const fromKey = type + DATE_RANGE_PREFIX_FROM;
        const toKey = type + DATE_RANGE_PREFIX_TO;

        return (
            <div className='date-ranges'>
                <DatePicker onChange={onChangeChoice(fromKey)} value={chosenValues[fromKey]} isClearable={isClearable} placeholder='Select Date'/>
                <DatePicker onChange={onChangeChoice(toKey)} value={chosenValues[toKey]} isClearable={isClearable} placeholder='Select Date'/>
            </div>
        );
    };

    const getDate = ({basicChoiceProps, item: {type, isDateRange, isClearable = true, isMultilineInput}}) => {
        const fromKey = type + DATE_RANGE_PREFIX_FROM;
        const toKey = type + DATE_RANGE_PREFIX_TO;

        const onChange = (value, endDate) => onChangeChoice()({[fromKey]: value, [toKey]: endDate});

        const datePickerProps = isDateRange ? {
            value: chosenValues[fromKey],
            endDate: chosenValues[toKey],
            onChange,
            isClearable,
            isMultilineInput,
            isDateRange: true,
            placeholder: 'Select Dates'
        } : basicChoiceProps;

        return (
            <DatePicker {...datePickerProps}/>
        );
    };

    const getChoice = (item, index) => {
        const {name, type, loadOptions, options = []} = item;
        const choiceType = getChoiceType(item);
        const areAvailableChoices = [choices.length, maxChoicesCount].every(value => activeChoices.length < value);
        const isAddableChoice = equal(index, 0) && areAvailableChoices;

        const normalizeChoice = ({name, type}) => ({label: name, value: type});
        const currentChoice = activeChoices.find(getEqual(type, 'type'));
        const filteredChoices = choices.filter(getIsChoiceUnused);
        const choiceTypeOptions = [currentChoice, ...filteredChoices].map(normalizeChoice);
        const selectChoiceTypeProps = {className: 'choice__type-select', value: type, options: choiceTypeOptions, onChange: onChangeChoiceType(type)};

        const areSelectionOptions = !!options.length;
        const choiceChangeHandler = onChangeChoice(type);
        const {inputType} = currentChoice || {};
        const basicChoiceProps = {
            value: chosenValues[type],
            placeholder: areSelectionOptions ? `Select ${name}` : 'Search…',
            onChange: choiceChangeHandler,
            ...inputType && {type: inputType}
        };
        const autocompleteLoadOptions = value => value && loadOptions(value);
        const onInputChange = ({target}) => choiceChangeHandler(target.value);

        const areMultipleChoices = choices.length > 1;
        const buttonProps = {
            className: classnames('choice__button', {'choice__button-remove': !isAddableChoice || isVertical}),
            type: BUTTON_TYPES.secondary,
            onClick: isAddableChoice && !isVertical ? addChoice : removeChoice(type)
        };
        const iconProps = {
            className: `choice__icon choice__icon_${isAddableChoice ? 'circle-plus-full' : 'trash'}`,
            type: isAddableChoice && !isVertical ? ICON_TYPES.add : ICON_TYPES.delete
        };

        const ActionButton = () => (
            <Column className='choice__col choice__col_action-bar'>
                <Button {...buttonProps}>
                    <Icon {...iconProps}/>
                    {!isVertical && (isAddableChoice ? 'Add New Search' : 'Remove Search')}
                </Button>
            </Column>
        );

        return (
            <Row className='choice' key={type}>
                <Column className='choice__col choice__col_type'>
                    {areMultipleChoices
                        ? <Select data-testid='select-choice-type' {...selectChoiceTypeProps}/>
                        : <div className='single-choice__button'>{name}</div>}
                </Column>

                <Column className='choice__col choice__col_field'>
                    {equal(choiceType, MULTIPLE_CHOICE_TYPES.select) && <Select options={options} {...basicChoiceProps}/>}
                    {equal(choiceType, MULTIPLE_CHOICE_TYPES.asyncAutocomplete) && <AsyncAutocomplete defaultOptions loadOptions={autocompleteLoadOptions} isCreatable={false} {...basicChoiceProps}/>}
                    {equal(choiceType, MULTIPLE_CHOICE_TYPES.dateRange) && getDateRange(item)}
                    {equal(choiceType, MULTIPLE_CHOICE_TYPES.date) && getDate({basicChoiceProps, item})}
                    {equal(choiceType, MULTIPLE_CHOICE_TYPES.input) && <Input {...basicChoiceProps} onChange={onInputChange} icon={ICON_TYPES.search}/>}
                </Column>

                {!isVertical && areMultipleChoices && <ActionButton/>}
                {isVertical && (!equal(index, 0) || activeChoices.length > 1) && <ActionButton/>}
            </Row>
        );
    };

    return (
        <div className={classnames('multiple-choice', {'multiple-choice-vertical': isVertical})}>
            {activeChoices.map(getChoice)}

            {isVertical && activeChoices.length < choices.length && (
                <Button className='add-new-search mt-10' type={BUTTON_TYPES.secondary} onClick={addChoice}>
                    <Icon type={ICON_TYPES.add}/>
                    Add New Search
                </Button>
            )}
        </div>
    );
};

MultipleChoice.propTypes = {
    isVertical: PropTypes.bool,
    defaultChoicesCount: PropTypes.number,
    maxChoicesCount: PropTypes.number,
    choices: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        choiceType: PropTypes.string,
        inputType: PropTypes.string,
        loadOptions: PropTypes.func,
        options: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        }))
    })),
    defaultValues: PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string
};

export {MultipleChoice as TestableMultipleChoice};
export default React.memo(MultipleChoice);
