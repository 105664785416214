import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import classnames from 'classnames';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import TimelineStepActionBar from '../TimelineStepActionBar';
import HorizontalTimeline from '../../shared/HorizontalTimeline';
import {getProfileInfo} from '../../../selectors/general';
import {formatDate, getItemKeyValue, scrollToComponent} from '../../../utils';
import './index.scss';

const TimelineStepInfo = ({isEditable, title, eta, description, notification, status, url: alias, children: subSteps = []}) => {
    const timelineStepInfoRef = useRef();
    const profileInfo = useSelector(getProfileInfo);

    useEffect(() => {
        const userOnboarded = profileInfo?.user_onboarded || [];
        const isUserOnboarded = userOnboarded.map(getItemKeyValue('onboarded_type')).includes('timeline');

        if (isUserOnboarded) {
            scrollToComponent(timelineStepInfoRef.current);
        }
    }, []);

    const actionBarProps = {alias, status, subSteps};

    return (
        <ContentSection className={classnames('timeline-step-info', {'timeline-step-info_readonly': !isEditable})} ref={timelineStepInfoRef}>
            <div className='step-info-header'>
                <Text className='step-info-header__title' type={TEXT_TYPES.bodyBold}>{title}</Text>

                {eta && (
                    <Text className='step-info-header__estimate'>
                        Est. time: <Text isInline type={TEXT_TYPES.bodyBold}>{eta}</Text>
                    </Text>
                )}
            </div>

            <Text><div className='step-info-description' dangerouslySetInnerHTML={{__html: description}}/></Text>

            <HorizontalTimeline className='step-info-timeline' parentAlias={alias} steps={subSteps} isEditable={isEditable}/>

            {notification && (
                <div className='step-info-review'>
                    <Icon className='step-info-review__icon' type={ICON_TYPES.stopwatch}/>

                    <div>
                        <Text type={TEXT_TYPES.bodyBold}>{notification.title}</Text>

                        <Text>{notification.description}</Text>

                        <Text className='review-expectation'>
                            Expected Completion Date: <span className='review-expectation__date'>{formatDate(notification.date, 'M/d/yyyy')}</span>
                        </Text>
                    </div>
                </div>
            )}

            {isEditable && <TimelineStepActionBar {...actionBarProps}/>}
        </ContentSection>
    );
};

TimelineStepInfo.propTypes = {
    isEditable: PropTypes.bool,
    url: PropTypes.string,
    title: PropTypes.string,
    eta: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({})),
    notification: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        date: PropTypes.string
    }),
    status: PropTypes.string
};

export {TimelineStepInfo as TestableTimelineStepInfo};
export default React.memo(TimelineStepInfo);
