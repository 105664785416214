import React from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import './index.scss';

const ImportCardDetails = ({title, data = []}) => {
    const getDetail = ({key, value}) => (
        <Row className='detail' key={key}>
            <Column sm={10} className='detail__key'><Text>{key}</Text></Column>
            <Column sm={2} className='detail__value'><Text>{value}</Text></Column>
        </Row>
    );

    return (
        <div className='import-card-details'>
            <div className='import-card-details__title-box'>
                <Heading className='import-card-details__title' type={HEADING_TYPES['5']}>{title}</Heading>
            </div>

            <div className='import-card-details__data'>
                {data.map(getDetail)}
            </div>
        </div>
    );
};

ImportCardDetails.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    data: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string
    }))
};

export {ImportCardDetails as TestableImportCardDetails};
export default ImportCardDetails;
