import React, {useEffect, useState} from 'react';
import Loader from '@frontend/ui-kit/Components/Loader';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {LookerEmbedSDK} from '@looker/embed-sdk';
import {requestReportingDashboardHiddenTiles} from '../../../actions/reporting';
import {LOOKER_URL} from '../../../constants';
import './index.scss';

const LookerDashboard = ({dashboardId, onUpdateFilters}) => {
    const dispatch = useDispatch();
    const [dashboard, setDashboard] = useState(null);
    const [dashboardOptions, setDashboardOptions] = useState(null);
    const [hiddenTiles, setHiddenTiles] = useState([]);
    const [areFiltersChanged, setAreFiltersChanged] = useState(false);
    const [isLoader, setIsLoader] = useState(true);

    useEffect(() => {
        (async () => {
            const {hiddenTiles, isSuccess} = await dispatch(requestReportingDashboardHiddenTiles(dashboardId));

            if (!isSuccess) {
                return;
            }

            setHiddenTiles(hiddenTiles);

            // FYI: we must have any API call before init method invoking in order to be sure accessToken is fresh.
            // Otherwise, for loading Looker dashboard user will have to reload page manually due to 401 http code (16.01.2022, Oleh);
            LookerEmbedSDK.init(LOOKER_URL, {url: '/api/v1/reports/looker_auth'});

            LookerEmbedSDK.createDashboardWithId(dashboardId)
                .appendTo('#looker-dashboard')
                .withClassName('looker-iframe')
                .withDynamicIFrameHeight()
                .on('dashboard:loaded', () => setIsLoader(false))
                .on('dashboard:run:start', ({dashboard}) => {
                    onUpdateFilters(dashboard.dashboard_filters);
                    setDashboardOptions(dashboard.options);
                })
                .on('dashboard:filters:changed', ({dashboard}) => {
                    onUpdateFilters(dashboard.dashboard_filters);
                    setAreFiltersChanged(true);
                })
                .build()
                .connect()
                .then(dashboard => setDashboard(dashboard))
                .catch(() => setIsLoader(false));
        })();

        return () => onUpdateFilters(null);
    }, [dashboardId, dispatch, onUpdateFilters]);

    useEffect(() => {
        if (!dashboard || !dashboardOptions) {
            return;
        }
        const [layout] = dashboardOptions.layouts;

        dashboard.setOptions({
            ...dashboardOptions,
            layouts: [{
                ...layout,
                dashboard_layout_components: layout.dashboard_layout_components.filter(({dashboard_element_id: id}) => !hiddenTiles.includes(Number(id)))
            }]
        });
    }, [dashboard, dashboardOptions, hiddenTiles]);

    useEffect(() => {
        if (!dashboard || !areFiltersChanged) {
            return;
        }

        dashboard.run();
        setAreFiltersChanged(false);
    }, [dashboard, areFiltersChanged]);

    return (
        <div className='looker-dashboard' id='looker-dashboard'>
            <Loader isLoader={isLoader} className='looker-dashboard__loader'/>
        </div>
    );
};

LookerDashboard.propTypes = {
    dashboardId: PropTypes.number.isRequired,
    onUpdateFilters: PropTypes.func
};

LookerDashboard.defaultProps = {
    onUpdateFilters: () => {}
};

export {LookerDashboard as TestableLookerDashboard};
export default React.memo(LookerDashboard);
